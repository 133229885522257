import React, { Fragment } from 'react';
import Dropzone from 'react-dropzone';
import {
  Grid,
  Paper,
  Divider,
  Typography,
  Button,
  Checkbox
} from '@material-ui/core';
import IconNewFile from '@material-ui/icons/AddCircleOutlineRounded';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconCloudUpload from '@material-ui/icons/CloudUploadRounded';
import { ChevronRightRounded } from '@material-ui/icons';
import ButtonUi from '../../../../../../components/ui/button/Button';
import { utils } from '../../../../utils';

const UploadProcessTitle = ({ uploadProgress }) => {
  const text = `Analisando o arquivo ${uploadProgress}`;

  return (<Typography variant="h4">{text}</Typography>);
};

const SelectFile = ({
  handleBack,
  loading,
  file,
  onSelectFile,
  resume,
  selectedContract,
  error,
  editOrder,
  editOrderCheckBox,
  handleEditOrderCheckBox,
  errorMsg,
  uploadProgress,
}) => (
  <Grid container spacing={16} justify='center'>
    {!file && (
      <Grid item xs={12}>
        <Grid container justify='center' className='mb-3'>
          <Grid container />
          <Grid container justify='center'>
            <Typography variant='subtitle2' className='mr-2'>
              <strong>{selectedContract.contracting_company_name && selectedContract.contracting_company_name + ':'}</strong>
            </Typography>

            <Typography variant='subtitle1'>
              {utils.formatCNPJ(selectedContract.contracting_company_cnpj)}
            </Typography>
          </Grid>
        </Grid>

        <Dropzone
          onDrop={file => onSelectFile(file[0])}
          disabled={file !== '' || (editOrder && !editOrderCheckBox)}
          className='form-upload'
        >
          <Grid
            container
            justify='center'
            direction='column'
            alignItems='center'
            style={{ padding: 24 }}
          >
            <IconNewFile color='disabled' style={{ fontSize: 100, color: '#3A10E0' }} />

            {!editOrder && (
              <Fragment>
                <Typography style={{
                  color: '#404040',
                  textAlign: 'center',
                  fontSize: '24px',
                }}> Para atualizar a base de vidas, clique aqui ou arraste o arquivo da planilha atualizado</Typography>

               
              </Fragment>
            )}

            {editOrder && (
              <Fragment>
                <Typography variant='h2'>Atenção</Typography>
                <Typography variant='h4' className='text-center mb-2'>
                  Já foi feito o upload de uma planilha, <br />um novo upload
                  irá substituir a anterior.
                </Typography>
                <Typography variant='h4' className='text-center mb-2'>
                  <Checkbox
                    checked={editOrderCheckBox}
                    onChange={handleEditOrderCheckBox}
                    onClick={event => event.stopPropagation()}
                  />
                  Tem certeza que deseja fazer um novo upload
                </Typography>
              </Fragment>
            )}

           <ButtonUi
              variant='link'
              disabled={file !== '' || (editOrder && !editOrderCheckBox)}
              endIcon={<ChevronRightRounded/>}
            >
              Selecionar arquivo
            </ButtonUi> 
            <input
              id='files'
              style={{ display: 'none' }}
              type='file'
              onChange={e => onSelectFile(e.target.files[0])}
            />
            <Typography className="mt-2" color="error">
              {errorMsg ? errorMsg : ''}
            </Typography>
          </Grid>

          {/* Erro upload */}
          {error && (
            <Grid container justify='center' style={{ padding: 24 }}>
              <Typography color='error' variant='h4' align='center'>
                {error}
              </Typography>
            </Grid>
          )}
        </Dropzone>
      </Grid>
    )}

    {file && (
      <Grid item xs={10}>
        <Paper>
          <Grid
            container
            justify='space-between'
            alignItems='center'
            style={{ padding: 16 }}
          >
            <Grid item xs={8} md={8}>
              <Typography variant='h4'>{file.name}</Typography>
              {loading && (
                <UploadProcessTitle uploadProgress={uploadProgress} />
              )}
              {!loading && resume && (
                <Grid container spacing={32}>
                  <Grid item>
                    <Typography variant='h4'>
                      {resume.totalNewEmployees} <strong>Novos</strong>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='h4'>
                      {resume.totalRecharges} <strong>Recorrentes</strong>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='h4'>
                      {resume.totalTerminations} <strong>Cancelados</strong>
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={4} md={4}>
              <Grid container justify='flex-end' alignItems='center'>
                <Button
                  size='small'
                  variant='outlined'
                  color='primary'
                  aria-label='Baixar Planilha de Exemplo'
                  onClick={handleBack}
                  disabled={loading}
                >
                  <IconCloudUpload style={{ marginRight: 16 }} />
                  NOVO UPLOAD
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {loading ? <LinearProgress /> : <Divider />}
        </Paper>
      </Grid>
    )}
  </Grid>
);

export default SelectFile;
