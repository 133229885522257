import React, { Component } from 'react';
import classNames from 'classnames';
import { Grid, Typography, Icon, CircularProgress } from '@material-ui/core';

import { ordersService } from '../../../../../../services';

import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: '24px, 8px, 0px',
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    margin: '24px 12px 24px',
  },
  table: {
    margin: '0px 12px 24px',
  },
  header: {
    margin: '0px 16px 0px 0px',
  },
  headerStatus: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    border: '1px solid',
    width: 'fit-content',
    borderRadius: '4px',
    padding: '2px 8px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
    gap: '4px',
  },
  headerContent: {
    color: 'var(--color-neutral-900) !important',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '22px',
  },
  cellHeader: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16.94px',
    textAlign: 'left',
    color: 'var(--color-neutral-900)',
  },
  cellValue: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16.94px',
    textAlign: 'left',
    color: 'var(--color-neutral-900)',
  },
  orderStatus: {
    padding:
      'var(--spacing-6xs) var(--spacing-5xs) var(--spacing-6xs) var(--spacing-5xs)',
    border: 'none',
    borderRadius: 'var(--spacing-6xs)',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
    gap: '4px',
  },
  icon: {
    fontSize: '16px',
    margin: '0px 4px 0px 0px',
  },
  worksheetFilename: {
    fontSize: '16px',
    fontFamily: 'Inter',
    color: '#1A1A1A',
    fontWeight: '700',
    marginTop: '8px',
    lineHeight: '24.5px',
  },
  errorContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    margin: '24px auto',
    padding: '12px 48px',
    color: '#93370D',
    backgroundColor: '#FEF0C7',
    border: '1px solid #93370D',
    borderRadius: '8px',
  },
  errorMessage: {
    color: '#93370D',
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: 1.5,
  },
  errorIcon: {
    margin: '0px 8px 0px 0px',
    color: '#93370D',
    backgroundColor: '#FEF0C7',
    fontSize: 36,
  },
});

function getManualOrderStatusColor(contractStatus) {
  switch (contractStatus) {
    case 'processed':
      return 'green-info';
    case 'partial_processing':
      return 'blue-info';
    case 'pending_information_filling':
    case 'awaiting_processing':
      return 'orange-info';
    case 'canceled':
    case 'Contrato Vencido':
      return 'red-info';
    default:
      return 'red-info';
  }
}

function getManualOrderStatusName(contractStatus) {
  switch (contractStatus) {
    case 'processed':
      return 'Processado';
    case 'partial_processing':
      return 'Processamento Parcial';
    case 'pending_information_filling':
      return 'Pendente de Execução';
    case 'awaiting_processing':
      return 'Aguardando Processamento';
    case 'canceled':
      return 'Cancelado';
    default:
      return 'red-info';
  }
}

function orderStatusColor(status) {
  switch (status) {
    case 'processed':
      return 'status-success';
    case 'partial_processing':
      return 'status-info';
    case 'expired':
    case 'reversed':
    case 'partial_reversed':
    case 'canceled':
      return 'status-error';
    case 'pending_information_filling':
    case 'active':
    default:
      return 'status-warning';
  }
}

function orderStatusIcon(status) {
  switch (status) {
    case 'processed':
      return 'person_edit';
    case 'partial_processing':
      return 'info';
    case 'expired':
    case 'reversed':
    case 'partial_reversed':
    case 'canceled':
      return 'dangerous';
    case 'pending_information_filling':
    case 'active':
    default:
      return 'emergency_home';
  }
}

function OrderStatsCard(props) {
  const { classes, item } = props;

  return (
    <Grid container direction='column' className={classes.root}>
      {/*Primeira Linha */}
      <Grid container item direction='column' className={classes.container}>
        <Grid container direction='row'>
          <Grid container item>
            <Grid item className={classes.header}>
              <Typography
                variant='subtitle1'
                className={classNames(
                  item.headerStatusColor,
                  classes.headerStatus,
                )}
              >
                <Icon
                  className={classNames(item.headerStatusColor, classes.icon)}
                >
                  {item.icon}
                </Icon>
                {item.headerStatus}
              </Typography>
            </Grid>
            <Grid item className={classes.header}>
              <Typography variant='h2' className={classes.headerContent}>
                {item.worksheetFilename}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Segunda linha */}
      <Grid container direction='column' className={classes.table}>
        <Grid container item direction='row'>
          <Grid item xs={3} md={3}>
            <Typography variant='subtitle1' className={classes.cellHeader}>
              {/* Data Criação */}
              {item.labelDataCreated}
            </Typography>
          </Grid>
          <Grid item xs={3} md={3}>
            <Typography variant='subtitle1' className={classes.cellHeader}>
              {/* Status */}
              {item.labelStatus}
            </Typography>
          </Grid>
          <Grid item xs={3} md={3}>
            <Typography variant='subtitle1' className={classes.cellHeader}>
              {/* Total Novos / Removidos funcionários */}
              {item.labelTotalRecords}
            </Typography>
          </Grid>
          <Grid item xs={3} md={3}>
            <Typography variant='subtitle1' className={classes.cellHeader}>
              {/* Label total removido ou adicionados */}
              {item.labelTotalSuccess}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction='row'>
          <Grid item xs={3} md={3}>
            <Typography variant='subtitle2' className={classes.cellValue}>
              {/* data -> Data de Criação  */}
              {item.dataCreated}
            </Typography>
          </Grid>
          <Grid item xs={3} md={3}>
            <div className={classes.cellValue}>
              {/* data -> Status */}
              <Typography
                variant='subtitle2'
                component='div'
                className={classNames(
                  classes.orderStatus,
                  item.orderStatusColor,
                )}
              >
                <Icon
                  className={classNames(item.orderStatusColor, classes.icon)}
                >
                  {item.orderStatusIcon}
                </Icon>
                {item.orderStatusName}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={3} md={3}>
            <Typography variant='subtitle2' className={classes.cellValue}>
              {/* data -> Total Novos / Removidos funcionários */}
              {item.totalRecords}
            </Typography>
          </Grid>
          <Grid item xs={3} md={3}>
            <Typography variant='subtitle2' className={classes.cellValue}>
              {/* data -> total removido ou adicionados */}
              {item.totalSuccess}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* Terceira linha */}
      <Grid container direction='column' className={classes.table}>
        <Grid container item direction='row'>
          <Grid item xs={3} md={3}>
            <Typography variant='subtitle1' className={classes.cellHeader}>
              {/* Executado em */}
              {item.labelExecutedIn}
            </Typography>
          </Grid>
          <Grid item xs={3} md={3}>
            <Typography variant='subtitle1' className={classes.cellHeader}>
              {/* vazio */}
              {item.labelEmpty || ' '}
            </Typography>
          </Grid>
          <Grid item xs={3} md={3}>
            <Typography variant='subtitle1' className={classes.cellHeader}>
              {/* Quanditade de Desligamentos */}
              {item.labelTotalTerminations}
            </Typography>
          </Grid>
          <Grid item xs={3} md={3}>
            <Typography variant='subtitle1' className={classes.cellHeader}>
              {/* Desligamentos Efetivados */}
              {item.labelTotalRemoved}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction='row'>
          <Grid item xs={3} md={3}>
            <Typography variant='subtitle2' className={classes.cellValue}>
              {/* data -> Executado em */}
              {item.executedIn}
            </Typography>
          </Grid>
          <Grid item xs={3} md={3}>
            <Typography variant='subtitle2' className={classes.cellValue}>
              {/* data -> vazio */}
              {item.labelEmpty || ' '}
            </Typography>
          </Grid>
          <Grid item xs={3} md={3}>
            <Typography variant='subtitle2' className={classes.cellValue}>
              {/* data -> Quanditade de Desligamentos */}
              {item.totalTerminations}
            </Typography>
          </Grid>
          <Grid item xs={3} md={3}>
            <Typography variant='subtitle2' className={classes.cellValue}>
              {/* Desligamentos Efetivados */}
              {item.totalRemoved}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const formLabels = {
  labelDataCreated: 'Data de Criação',
  labelDataCreated: 'Data de Criação',
  labelStatus: 'Status',
  labelTotalRecords: 'Quantidade de Novas Vidas',
  labelTotalSuccess: 'Vidas Incluídas',
  labelExecutedIn: 'Executado em',
  labelEmpty: '',
  labelTotalTerminations: 'Quanditade de Desligamentos',
  labelTotalRemoved: 'Desligamentos Efetivados',
};

class OrderStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: [],
      loading: true,
    };
  }

  loadManualOrderStats(contractId) {

    ordersService
      .getManualOrderStats(contractId)
      .then((data) => {
        const { response, success } = data;

        if (!success) {
          return;
        }

        const stats = response.map((item) => {
          const {
            root_order_id,
            root_order_status_name,
            order_status_name,
            worksheet_filename,
            total_new_employees,
            total_terminations,
            total_new_employees_added,
            total_removed,
            created_at,
            updated_at,
          } = item;

          return {
            key: root_order_id,
            ...formLabels,
            worksheetFilename: worksheet_filename,
            headerStatus: getManualOrderStatusName(root_order_status_name),
            headerStatusColor: getManualOrderStatusColor(
              root_order_status_name,
            ),
            icon: orderStatusIcon(root_order_status_name),

            orderStatusName: getManualOrderStatusName(order_status_name),
            orderStatusColor: orderStatusColor(order_status_name),
            orderStatusIcon: orderStatusIcon(order_status_name),

            totalRecords: total_new_employees,
            totalSuccess: total_new_employees_added,
            totalTerminations: total_terminations,
            totalRemoved: total_removed,

            dataCreated: moment(created_at).format('DD/MM/YYYY HH:mm'),
            executedIn: updated_at ? moment(updated_at).format('DD/MM/YYYY HH:mm') : '--:--',
          };
        });

        this.setState({
          stats,
          loading: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  componentWillMount() {
    const { selectedContract } = this.props;

    if (!selectedContract || !selectedContract.id) {
      this.setState({
        loading: false,
      });

      return;
    }

    this.loadManualOrderStats(selectedContract.id);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedContract.id !== nextProps.selectedContract.id) {
      this.setState({
        stats: [],
        loading: true,
      });

      this.loadManualOrderStats(nextProps.selectedContract.id);
    } else {
      this.setState({
        stats: [],
        loading: false,
      });
    }
  }

  render() {
    const { classes, selectedContract } = this.props;
    const { stats, loading } = this.state;

    return (
      <Grid container direction='column' className={classes.root}>
        {loading && (
          <Grid item container justify='center'>
            <CircularProgress
              mode='indeterminate'
              style={{ color: '#3A10E0', margin: 100 }}
            />
          </Grid>
        )}
        {stats.length === 0 && !loading && (
          <Grid item container justify='center' alignItems='center'>
            <Grid item className={classes.errorContent}>
              <Icon className={classes.errorIcon}>dangerous</Icon>
              <Typography className={classes.errorMessage} variant='h6'>
                {selectedContract && selectedContract.id
                  ? 'Nenhum registro encontrado'
                  : 'Selecione um contrato para visualizar os registros'}
              </Typography>
            </Grid>
          </Grid>
        )}
        {stats.length > 0 &&
          !loading &&
          stats.map((item) => (
            <OrderStatsCard classes={classes} item={item} key={item.key} />
          ))}
      </Grid>
    );
  }
}

export default withStyles(styles)(OrderStats);
