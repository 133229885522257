import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  modalEmitAlert,
  modalAlertShowLoading,
  modalHiddenAlert,
} from '../../../../../../../actions/ModalAlertActions';

import { Grid, FormControl, Typography, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ChevronLeft';

import ContainerHeader from '../../../../../../../components/ContainerHeader/index';
import Select from '../../../../../../../components/ui/select';
import TextField from '../../../../../../../components/ui/TextField';
import Button from '../../../../../../../components/ui/button/Button';
import CustomCircularProgress from '../../../../../../../components/ui/progress/CustomizedCircularProgress';

import { employeeService } from '../../../../../../../services/employeeService';
import { SUCCESS, ERROR } from '../../../../../../../constants/ActionTypes';
import { utils } from '../../../../../../routes/utils';

import ConfirmationDialog, {
  ConfirmationDialogBody,
  ConfirmationDialogBodyCancel,
} from './ConfirmationDialog';

import { styles } from './styles';

const formEmployee = {
  errors: {
    name: 'Nome é obrigatório.',
    cpf: 'CPF é obrigatório.',
    gender: 'Gênero é obrigatório.',
    birthDate: 'Data de nascimento é obrigatória.',
    phone: 'Telefone é obrigatório.',
    email: 'E-mail é obrigatório.',
  },
};

const emptyEmployee = {
  name: '',
  cpf: '',
  email: '',
  phone: '',
  gender: '',
  birthDate: '',
  errors: {
    name: '',
    cpf: '',
    gender: '',
    birthDate: '',
    phone: '',
    email: '',
  },
  touched: {
    name: false,
    cpf: false,
    gender: false,
    birthDate: false,
    phone: false,
    email: false,
  },
};

class AddNewEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      cpfLoading: false,
      employee: emptyEmployee,
      hasContract: false,
      confirm: false,
      cancel: false,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({ hasContract: !!nextProps.selectedContract.id });
  };

  componentDidMount() {
    this.setState({ hasContract: !!this.props.selectedContract.id });
  }

  handleInputChange(e) {
    let { name, value } = e.target;

    if (name === 'cpf') {
      value = utils.handleCPF(value);
    } else if (name === 'phone') {
      value = utils.handleCellphone(value);
    }

    this.setState((prevState) => ({
      employee: {
        ...prevState.employee,
        [name]: value,
      },
    }));
  }

  async checkEmployeeByCPF(cpf) {
    try {
      const cnpj = this.props.selectedContract.contracting_company_cnpj;
      const sanitizeCPF = utils.sanitizeCPF(cpf);
      this.setState({ cpfLoading: true, loading: true });
      const response = await employeeService.getAccountByCPFAndCNPJ(
        sanitizeCPF,
        cnpj
      );
      const { success, response: result } = response.data;
      if (success && result && result.code === 'active') {
        return 'CPF já cadastrado.';
      }
    } catch (error) {
      console.error(error);
      return 'Erro ao validar CPF.';
    } finally {
      this.setState({ loading: false, cpfLoading: false });
    }

    return '';
  }

  async validate(e) {
    const { name, value } = e.target;
    let error = '';

    if (name === 'cpf' && value !== '') {
      error = utils.validateCpf(e.target.value);
      error = error === '' ? await this.checkEmployeeByCPF(value) : error;
    } else if (name === 'email' && value !== '') {
      error = utils.validateEmail(e.target.value);
    } else if (name === 'phone' && value !== '') {
      error = utils.validatePhone(e.target.value);
    } else if (name === 'birthDate') {
      error = utils.validateBirthDate(value);
    } else {
      error = value === '' ? formEmployee.errors[name] : '';
    }

    this.setState((prevState) => ({
      employee: {
        ...prevState.employee,
        errors: {
          ...prevState.employee.errors,
          [name]: error,
        },
        touched: {
          ...prevState.employee.touched,
          [name]: true,
        },
      },
    }));
  }

  isFormValid() {
    const { employee } = this.state;
    const hasErrors = Object.values(employee.errors).some(
      (error) => error !== ''
    );

    const isEmptyForm = Object.values(employee)
      .filter((value) => typeof value !== 'object')
      .some((value) => !String(value));

    return !hasErrors && !isEmptyForm;
  }

  handleBack() {
    const location = {
      pathname: '/app/funcionarios',
      state: { success: false, message: 'Cancelado.', flashed: false },
    };

    const { employee } = this.state;
    const hasTouched = Object.values(employee.touched).some(
      (item) => item === true
    );

    if (!hasTouched) {
      this.props.history.push(location);
      return;
    }

    this.setState({ cancel: hasTouched });
  }

  handleConfirmDialogCancel(value) {
    const location = {
      pathname: '/app/funcionarios',
      state: { success: false, message: 'Cancelado.', flashed: false },
    };

    this.setState({ cancel: false });

    if (value !== SUCCESS) {
      return;
    }

    this.props.history.push(location);
  }

  handleNext() {
    const { employee } = this.state;
    const { modalEmitAlert } = this.props;

    const hasErrors = Object.values(employee.errors).some(
      (error) => error !== ''
    );

    if (hasErrors) {
      modalEmitAlert(
        'Por favor, preencha todos os campos corretamente.',
        ERROR
      );
      return;
    }

    this.setState({ confirm: true });

    return;
  }

  handleConfirmDialogClose(value) {
    this.setState({ confirm: false });

    if (value !== SUCCESS) {
      return;
    }

    const { employee } = this.state;
    const { 
      selectedContract,
      modalAlertShowLoading,
      modalHiddenAlert 
    } = this.props;

    const payload = [
      {
        name: employee.name,
        cpf: utils.sanitizeCPF(employee.cpf),
        email: employee.email,
        phone: utils.sanitizePhone(employee.phone),
        gender: employee.gender,
        birthDate: employee.birthDate,
      },
    ];

    modalAlertShowLoading();
    this.setState({ loading: true });
    const location = {
      pathname: '/app/funcionarios',
      state: { success: false, message: '', error: false, flashed: false },
    };

    employeeService
      .addEmployee(selectedContract.id, payload)
      .then((response) => {
        if (response.data.success) {
          return response.data.response.employees;
        }
        throw new Error('Erro ao cadastrar funcionário.');
      })
      .then((employees) => {
        employees.forEach((emp) => {
          if (emp.success) {
            location.state.success = true;
            location.state.message = 'Funcionário cadastrado com sucesso.';
          } else {
            location.state.success = false;
            location.state.message = `Erro ao cadastrar funcionário ${emp.cpf}: ${emp.error}`;
            location.state.error = true;
          }
        });
      })
      .catch((error) => {
        console.error(error);
        location.state.success = false;
        location.state.message = `Ocorreu um erro no servidor ao cadastrar o funcionário. Por favor, tente novamente mais tarde.`;
        location.state.error = true;
      })
      .finally(() => {
        modalHiddenAlert();
        this.setState({ loading: false }, () => {
          this.props.history.push(location);
        });
      });
  }

  render() {
    const { classes } = this.props;
    const { loading, cpfLoading, employee, hasContract } = this.state;

    return (
      <div className='app-wrapper'>
        <Grid container direction='column'>
          <ContainerHeader title='Adicionar funcionário' />
        </Grid>
        {!hasContract && (
          <Grid item xs={12}>
            <Typography
              variant='h6'
              color='textSecondary'
              style={{ margin: '20px 0' }}
            >
              Selecione um contrato
            </Typography>
          </Grid>
        )}
        {hasContract && (
          <div className={classes.containerWrapper}>
            <Grid
              container
              direction='column'
              justify='flex-start'
              alignItems='flex-start'
              spacing={0}
              className={classes.root}
            >
              <Grid
                container
                item
                direction='row'
                justify='flex-start'
                alignItems='flex-start'
                spacing={0}
              >
                <Grid item xs={8} md={8} className={classes.columnSpacer}>
                  <TextField
                    small={true}
                    name='name'
                    id={'frm_employee_name'}
                    label='Nome'
                    required={true}
                    value={employee.name}
                    onChange={(e) => this.handleInputChange(e)}
                    onBlur={(e) => this.validate(e)}
                    error={employee.touched.name && employee.errors.name !== ''}
                    type='text'
                    disabled={loading}
                    formInputHelper={{
                      title: 'Nome',
                      text: employee.errors.name,
                      showIcon: true,
                      isShow:
                        employee.touched.name && employee.errors.name !== '',
                    }}
                  />
                </Grid>
                <Grid item xs={4} md={4} className={classes.columnSpacer}>
                  <TextField
                    small={true}
                    name='cpf'
                    id={'frm_employee_cpf'}
                    label='CPF'
                    required={true}
                    value={employee.cpf}
                    onChange={(e) => this.handleInputChange(e)}
                    onBlur={(e) => this.validate(e)}
                    error={employee.touched.cpf && employee.errors.cpf !== ''}
                    type='text'
                    disabled={loading}
                    endAdornment={
                      <CustomCircularProgress hidden={!cpfLoading} />
                    }
                    formInputHelper={{
                      title: 'CPF',
                      text: employee.errors.cpf,
                      showIcon: true,
                      isShow:
                        employee.touched.cpf && employee.errors.cpf !== '',
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                direction='row'
                justify='flex-start'
                alignItems='flex-start'
                spacing={0}
                className={classes.rowSpacer}
              >
                <Grid item xs={4} md={4} className={classes.columnSpacer}>
                  <TextField
                    small={true}
                    name='email'
                    id={'frm_employee_email'}
                    label='Email'
                    required={true}
                    value={employee.email}
                    onChange={(e) => this.handleInputChange(e)}
                    onBlur={(e) => this.validate(e)}
                    error={
                      employee.touched.email && employee.errors.email !== ''
                    }
                    type='text'
                    disabled={loading}
                    formInputHelper={{
                      title: 'Email',
                      text: employee.errors.email,
                      showIcon: true,
                      isShow:
                        employee.touched.email && employee.errors.email !== '',
                    }}
                  />
                </Grid>
                <Grid item xs={4} md={4} className={classes.columnSpacer}>
                  <TextField
                    small={true}
                    name='birthDate'
                    id={'frm_employee_birthDate'}
                    label='Data de Nascimento'
                    value={employee.birthDate}
                    onChange={(e) => this.handleInputChange(e)}
                    onBlur={(e) => this.validate(e)}
                    error={employee.errors.birthDate !== ''}
                    type='date'
                    disabled={loading}
                    formInputHelper={{
                      title: 'Data de Nascimento',
                      text: employee.errors.birthDate,
                      showIcon: true,
                      isShow: employee.errors.birthDate !== '',
                    }}
                  />
                </Grid>
                <Grid item xs={4} md={4} className={classes.columnSpacer}>
                  <TextField
                    small={true}
                    name='phone'
                    id={'frm_employee_phone'}
                    label='Telefone'
                    required={true}
                    value={employee.phone}
                    onChange={(e) => this.handleInputChange(e)}
                    onBlur={(e) => this.validate(e)}
                    error={
                      employee.touched.phone && employee.errors.phone !== ''
                    }
                    type='text'
                    disabled={loading}
                    formInputHelper={{
                      title: 'Telefone',
                      text: employee.errors.phone,
                      showIcon: true,
                      isShow:
                        employee.touched.phone && employee.errors.phone !== '',
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                direction='row'
                justify='flex-start'
                alignItems='flex-start'
                spacing={0}
                className={classes.rowSpacer}
              >
                <Grid item xs={4} md={4} className={classes.columnSpacer}>
                  <FormControl fullWidth margin='none'>
                    <Select
                      name='gender'
                      id={'frm_employee_gender'}
                      label='Gênero'
                      value={employee.gender}
                      onChange={(e) => {
                        this.handleInputChange(e);
                        this.validate(e);
                      }}
                      onBlur={(e) => {
                        this.validate(e);
                      }}
                      isDisabled={loading}
                      error={
                        employee.touched.gender && employee.errors.gender !== ''
                      }
                      errorMessage={
                        employee.touched.gender && employee.errors.gender
                      }
                      options={[
                        { value: 'M', name: 'Masculino' },
                        { value: 'F', name: 'Feminino' },
                      ]}
                    ></Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                item
                direction='row'
                justify='flex-start'
                alignItems='flex-start'
                spacing={0}
                className={classes.rowSpacer}
              >
                <Grid item xs={6} md={6}>
                  <Button
                    isDisabled={false}
                    onClick={() => this.handleBack()}
                    color='primary'
                    variant='link'
                    size='sm'
                  >
                    <ArrowBack />
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={6} md={6} container justify='flex-end'>
                  <Button
                    variant='primary'
                    size='md'
                    color='primary'
                    onClick={() => this.handleNext()}
                    endIcon={
                      <Icon className={classes.iconSmall}>check_circle</Icon>
                    }
                    isDisabled={this.isFormValid() ? false : true}
                  >
                    Concluir
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
        <ConfirmationDialog
          open={this.state.confirm}
          onClose={(value) => this.handleConfirmDialogClose(value)}
          title=''
          subTitle='Ao clicar em confirmar, você vai incluir no sistema'
          content={
            <ConfirmationDialogBody
              cpf={this.state.employee.cpf}
              name={this.state.employee.name}
            />
          }
        />
        <ConfirmationDialog
          open={this.state.cancel}
          onClose={(value) => this.handleConfirmDialogCancel(value)}
          title=''
          subTitle='Tem certeza que deseja cancelar?'
          content={<ConfirmationDialogBodyCancel />}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ contract }) => {
  const { selectedContract } = contract;
  return { selectedContract };
};
export default connect(mapStateToProps, {
  modalEmitAlert,
  modalAlertShowLoading,
  modalHiddenAlert,
})(withRouter(withStyles(styles)(AddNewEmployee)));
