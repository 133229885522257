import React, { Component, Fragment } from 'react';
import { FormControl, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Select from '../../../../../../components/ui/select';
import TextField from '../../../../../../components/ui/TextField';

import { utils } from '../../../../utils';
import { pjService } from '../../../../../../services/pjService';

import { styles } from '../styles';

function HRManagerForm(props) {
  // this.state.hr_resps[index].personFetched ||
  const {
    handleInputChange,
    validate,
    hr_manager,
    index,
    loading,
    confirmation,
    personFetched,
    classes,
  } = props;
  return (
    <Grid
      container
      direction='column'
      justify='flex-start'
      alignItems='flex-start'
      spacing={0}
      className={classes.root}
    >
            <Grid
        container
        item
        direction='row'
        spacing={0}
        className={classes.rowSpacer}
      ><hr className={classes.lineSeparator} /></Grid>
      <Grid
        container
        item
        direction='row'
        justify='flex-start'
        alignItems='flex-start'
        spacing={0}
        className={classes.rowSpacer}
      >
        <Grid item xs={4} md={4} className={classes.columnSpacer}>
          <TextField
            small={true}
            name='cpf'
            id={'hr_manager_cpf' + index}
            label='CPF'
            required={true}
            value={hr_manager.resp.cpf}
            onChange={(e) => handleInputChange(e, index)}
            onBlur={(e) => validate(e, index)}
            error={hr_manager.touched.cpf && hr_manager.errors.cpf !== ''}
            type='text'
            disabled={loading || confirmation}
            formInputHelper={{
              title: 'CPF',
              text: hr_manager.errors.cpf,
              showIcon: true,
              isShow: hr_manager.touched.cpf && hr_manager.errors.cpf !== '',
            }}
          />
        </Grid>
        <Grid item xs={8} md={8} className={classes.columnSpacer}>
          <TextField
            small={true}
            name='nome'
            id={'hr_manager_nome' + index}
            label='Nome'
            required={true}
            value={hr_manager.resp.nome}
            onChange={(e) => handleInputChange(e, index)}
            onBlur={(e) => validate(e, index)}
            error={hr_manager.touched.nome && hr_manager.errors.nome !== ''}
            type='text'
            disabled={ loading || confirmation}
            formInputHelper={{
              title: 'Nome',
              text: hr_manager.errors.nome,
              showIcon: true,
              isShow: hr_manager.touched.nome && hr_manager.errors.nome !== '',
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction='row'
        justify='flex-start'
        alignItems='flex-start'
        spacing={0}
        className={classes.rowSpacer}
      >
        <Grid item xs={4} md={4} className={classes.columnSpacer}>
          <FormControl fullWidth margin='none'>
            <Select
              name='sexo'
              id={'hr_manager_sexo' + index}
              label='Gênero'
              value={hr_manager.resp.sexo}
              onChange={(e) => { 
                handleInputChange(e, index);
                validate(e, index);
              }}
              onBlur={(e) => {
                validate(e, index);
              }}
              isDisabled={personFetched || loading || confirmation}
              error={hr_manager.touched.sexo && hr_manager.errors.sexo !== ''}
              errorMessage={hr_manager.touched.sexo && hr_manager.errors.sexo}
              options={[
                { value: 'M', name: 'Masculino' },
                { value: 'F', name: 'Feminino' },
              ]}
            ></Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} md={4} className={classes.columnSpacer}>
          <TextField
            small={true}
            name='data_nascimento'
            id={'hr_manager_data_nascimento' + index}
            label='Data de Nascimento'
            value={hr_manager.resp.data_nascimento}
            onChange={(e) => handleInputChange(utils.capDate(e), index)}
            onBlur={(e) => validate(e, index)}
            error={hr_manager.errors.data_nascimento !== ''}
            type='date'
            disabled={ loading || confirmation}
            formInputHelper={{
              title: 'Data de Nascimento',
              text: hr_manager.errors.data_nascimento,
              showIcon: true,
              isShow: hr_manager.errors.data_nascimento !== '',
            }}
          />
        </Grid>
        <Grid item xs={4} md={4} className={classes.columnSpacer}>
          <TextField
            small={true}
            name='telefone'
            id={'hr_manager_telefone' + index}
            label='Telefone'
            required={true}
            value={hr_manager.resp.telefone}
            onChange={(e) => handleInputChange(e, index)}
            onBlur={(e) => validate(e, index)}
            error={
              hr_manager.touched.telefone && hr_manager.errors.telefone !== ''
            }
            type='text'
            disabled={ loading || confirmation}
            formInputHelper={{
              title: 'Telefone',
              text: hr_manager.errors.telefone,
              showIcon: true,
              isShow:
                hr_manager.touched.telefone &&
                hr_manager.errors.telefone !== '',
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction='row'
        justify='flex-start'
        alignItems='flex-start'
        spacing={0}
        className={classes.rowSpacer}
      >
        <Grid item xs={4} md={4} className={classes.columnSpacer}>
          <TextField
            small={true}
            name='email'
            id={'hr_manager_email' + index}
            label='Email'
            required={true}
            value={hr_manager.resp.email}
            onChange={(e) => handleInputChange(e, index)}
            onBlur={(e) => validate(e, index)}
            error={hr_manager.touched.email && hr_manager.errors.email !== ''}
            type='text'
            disabled={loading || confirmation}
            formInputHelper={{
              title: 'Email',
              text: hr_manager.errors.email,
              showIcon: true,
              isShow:
                hr_manager.touched.email && hr_manager.errors.email !== '',
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

class HR extends Component {
  constructor(props) {
    super(props);
    const { replicate, financialResp} = props;
    const nome = replicate ? financialResp.nome : '';
    const sexo = replicate ? financialResp.sexo : '';
    const cpf = replicate ? financialResp.cpf : '';
    const data_nascimento = replicate ? financialResp.data_nascimento : '';
    const telefone = replicate ? financialResp.telefone: '';
    const email = replicate ? financialResp.email : '';
    this.state = {
      qtdResponsaveis: 1,
      hr_resps: [
        {
          resp: {
            nome,
            sexo,
            cpf,
            data_nascimento,
            telefone,
            email,
          },
          errors: {
            nome: !nome ? 'Por favor, insira um nome.' : '',
            cpf: !cpf ? 'Por favor, insira um CPF.' : '',
            sexo: !sexo ? 'Por favor, insira um sexo.': '',
            data_nascimento: '',
            telefone: !telefone ? 'Por favor, insira o telefone.' : '',
            email: !email ? 'Por favor, insira um e-mail.' : '',
          },
          touched: {
            nome: false,
            sexo: false,
            cpf: false,
            data_nascimento: false,
            telefone: false,
            email: false
          },
          personFetched: false
        }
      ],
      erro: '',
      loading: false
    };
  }


  componentDidMount() {
    this.props.setActiveComponentStep(this);
    if (this.props.hr_resps.length > 0 && this.props.hr_resps[0].resp.nome) {
      const hr_resps = [];
      for (const resp of this.props.hr_resps) {
        const newResp = {
          resp: {
            nome: resp.resp.nome,
            sexo: resp.resp.sexo,
            cpf: resp.resp.cpf,
            data_nascimento: resp.resp.data_nascimento,
            telefone: resp.resp.telefone,
            email: resp.resp.email,
          },
          errors: {
            nome:
              resp.resp.nome === null || resp.resp.nome === ''
                ? 'Por favor, insira o nome.'
                : '',
            cpf:
              resp.resp.cpf === null || resp.resp.cpf === ''
                ? 'Por favor, insira o CPF.'
                : '',
            sexo:
              resp.resp.sexo === null || resp.resp.sexo === ''
                ? 'Por favor, insira o sexo.'
                : '',
            data_nascimento: '',
            telefone:
              resp.resp.telefone === null || resp.resp.telefone === ''
                ? 'Por favor, insira o telefone.'
                : '',
            email:
              resp.resp.email === null || resp.resp.email === ''
                ? 'Por favor, insira o email.'
                : '',
          },
          touched: {
            nome: true,
            sexo: true,
            cpf: true,
            data_nascimento: true,
            telefone: true,
            email: true,
          },
        };
        hr_resps.push(newResp);

      }

      this.setState({
        hr_resps,
        qtdResponsaveis: this.props.hr_resps.length,
      });

      this.props.currentComponentStepChange(this.inValidateForm(hr_resps));
    } else {
      this.props.currentComponentStepChange(this.inValidateForm(this.state.hr_resps));
    }
  }

  updateCurrentState() {
    this.props.setInputs(this.state.hr_resps);
  }

  handleInputChange = (event, index) => {
    const hr_resps = this.state.hr_resps;
    const responsavel = hr_resps[index];
    let value = event.target.value;

    if (event.target.name === 'telefone') {
      value = utils.handleCellphone(value);
    }

    if (event.target.name === 'cpf') {
      value = utils.handleCPF(value);
    }

    responsavel.resp[event.target.name] = value;
    hr_resps[index] = responsavel;

    this.setState({ hr_resps });
  };

  handleCpfFetch = (response, index) => {
    if (response.success) {
      const person = response.response;
      person.birth_date = !person.birth_date
        ? ''
        : (person.birth_date = person.birth_date.slice(0, 10));

      if (person.phone) {
        person.phone =
          person.phone.length > 0
            ? utils.handleCellphone(
                person.phone[0].area_code.split('0')[1] +
                  person.phone[0].number,
              )
            : '';
      } else {
        person.phone = '';
      }

      const newResp = this.state.hr_resps[index];
      const hr_resps = this.state.hr_resps;
      newResp.resp.nome = person.name;
      newResp.resp.sexo = person.gender;

      newResp.resp.email = person.email;
      newResp.errors.email =
        person.email === '' ? hr_resps[index].errors.email : '';
      newResp.touched.email = person.email === '' ? false : true;

      newResp.errors.nome = '';
      newResp.errors.sexo = '';
      newResp.touched.nome = true;
      newResp.touched.sexo = true;

      newResp.resp.data_nascimento = person.birth_date;
      newResp.resp.telefone = person.phone;
      newResp.touched.telefone = person.phone !== '';
      newResp.touched.data_nascimento = person.birth_date !== '';
      newResp.errors.telefone =
        person.phone !== '' ? '' : hr_resps[index].errors.telefone;
      newResp.errors.data_nascimento =
        person.birth_date !== '' ? '' : hr_resps[index].errors.data_nascimento;

      newResp.personFetched = true;
      hr_resps[index] = newResp;

      this.setState({
        hr_resps,
        loading: false,
      });

      this.props.currentComponentStepChange(this.inValidateForm(hr_resps));
    } else {
      const newResp = this.state.hr_resps[index];
      const hr_resps = this.state.hr_resps;
      newResp.personFetched = false;
      hr_resps[index] = newResp;
      this.setState({ hr_resps, loading: false });
    }
  };

  validate = (e, index) => {
    let error = e.target.value ? '' : 'Por Favor, preencha este campo.';
    if (e.target.name === 'cpf') {
      error = utils.validateCpf(e.target.value);
      if (error === '') {
        this.setState({ loading: true });
        pjService
          .getByCpf(
            utils.removeNonNumericCharacters(
              `${this.state.hr_resps[index].resp.cpf}`,
            ),
          )
          .then((response) => this.handleCpfFetch(response, index));
      }
    }

    if (e.target.name === 'telefone') {
      error =
        e.target.value.length >= 14
          ? ''
          : 'Por favor, insira um telefone válido.';
    }

    if (e.target.type === 'date') {
      error =
        utils.validateDate(e.target.value) || e.target.value === ''
          ? ''
          : 'Por favor, insira uma data válida.';
    }

    if (e.target.name === 'email') {
      error =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          e.target.value,
        )
          ? ''
          : 'Por favor, insira um email válido.';
    }
    const newResp = this.state.hr_resps[index];
    newResp.errors[e.target.name] = error;
    newResp.touched[e.target.name] = true;
    const hr_resps = this.state.hr_resps;
    hr_resps[index] = newResp;

    this.setState({
      hr_resps,
    });

    this.props.currentComponentStepChange(this.inValidateForm(hr_resps));
  };

  setInitialResps = (resps) => {
    if (this.props.hr_resps.length > 0) {
      for (let i = 0; i < this.props.hr_resps.length; i++) {
        if (resps.length >= i + 1) {
          resps[i] = this.props.hr_resps[i];
        } else {
          break;
        }
      }
    }

    return resps;
  };

  createResponsaveisFields = (qtdResponsaveis) => {
    let erro = '';
    qtdResponsaveis = parseInt(qtdResponsaveis);
    if (!qtdResponsaveis || qtdResponsaveis < 0) {
      qtdResponsaveis = 1;
      erro = 'O número mínimo de responsáveis deve ser 1';
    } else if (qtdResponsaveis > 20) {
      qtdResponsaveis = 20;
    }

    let hr_resps = this.state.hr_resps;
    if (qtdResponsaveis > this.state.qtdResponsaveis) {
      while (hr_resps.length < qtdResponsaveis) {
        hr_resps.push({
          resp: {
            nome: '',
            sexo: '',
            cpf: '',
            data_nascimento: '',
            telefone: '',
            email: '',
          },
          errors: {
            nome: 'Por favor, insira um nome.',
            cpf: 'Por favor, insira um CPF.',
            sexo: 'Por favor,insira um sexo.',
            data_nascimento: '',
            telefone: '',
            email: 'Por favor, insira um e-mail.',
          },
          touched: {
            nome: false,
            sexo: false,
            cpf: false,
            data_nascimento: false,
            telefone: false,
            email: false,
          },
        });
      }
      hr_resps = this.setInitialResps(hr_resps);
      this.setState({ qtdResponsaveis, hr_resps, erro });
      this.props.currentComponentStepChange(this.inValidateForm(hr_resps));
    } else {
      if (qtdResponsaveis < this.state.qtdResponsaveis) {
        while (hr_resps.length > qtdResponsaveis) {
          hr_resps.pop();
        }
        hr_resps = this.setInitialResps(hr_resps);
        this.setState({ qtdResponsaveis, hr_resps, erro });
        this.props.currentComponentStepChange(this.inValidateForm(hr_resps));
      }
    }
  };

  inValidateForm(hr_resps) {
    if (this.state.qtdResponsaveis < 1) return false;
    let error = hr_resps.every((resp) => {
      const { nome, sexo, cpf, data_nascimento, telefone, email } = resp.errors;

      return (!nome && !sexo && !cpf && !data_nascimento && !telefone && !email);
    });

    return !error;
  };

  render() {
    const { hr_resps: resps, loading } = this.state;
    const { classes, confirmation } = this.props;
    return (
      <Fragment>
        <Grid
          container
          direction='column'
          justify='flex-start'
          alignItems='flex-start'
          spacing={0}
          className={classes.root}
        >
            <Grid
              container
              item
              direction='row'
              justify='flex-start'
              alignItems='flex-start'
              spacing={0}
              className={classes.rowSpacer}
            >
              <Grid item xs={4} md={4} className={classes.columnSpacer}>
                <TextField
                  small={true}
                  name='qtdResponsaveis'
                  id='qtdResponsaveis'
                  label='Quantidade de Responsáveis'
                  inputProps={{ min: 1, max: 20, step: 1 }}
                  value={this.state.qtdResponsaveis}
                  disabled={loading || confirmation}
                  onChange={(e) =>
                    this.createResponsaveisFields(e.target.value)
                  }
                  error={this.state.erro !== ''}
                  type='number'
                  formInputHelper={{
                    title: 'Quantidade de Responsáveis',
                    text: this.state.erro,
                    showIcon: true,
                    isShow: this.state.erro !== '',
                  }}
                />
              </Grid>
            </Grid>
          {this.state.qtdResponsaveis > 0 && resps.map((resp, index) => {
            return (
              <HRManagerForm
                key={index}
                handleInputChange={this.handleInputChange}
                validate={this.validate}
                hr_manager={resp}
                index={index}
                loading={loading}
                confirmation={confirmation}
                personFetched={resp.personFetched}
                classes={classes}
              />
            );
          })}
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(HR);
