import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import EmployeesConsulting from './routes/consulting';
import NewEmployeesRegister from './routes/register/subcomponents/AddNewEmployee';

const Employees = ({ match }) => (
  <div>
    {!localStorage.getItem('token') && (
      <Redirect
        to={{
          pathname: '/signin'
        }}
        push
      />
    )}
    <Switch>
      <Route path={`${match.url}`} exact component={EmployeesConsulting} />
      <Route path={`${match.url}/adicionar`} exact component={NewEmployeesRegister} />
    </Switch>
  </div>
);

export default Employees;
