import { BASE_URL } from "../config/env";

export function getAccounts(id) {
  const token = localStorage.getItem('token');

  return fetch(`${BASE_URL}/accounts/getAccountsByContract`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      id: id,
    }),
  }).then((response) => {
    return response.json().then((json) => {
      return {
        data: json,
        status: response.status,
      };
    });
  });
}

export function getLogs(account_id) {
  const token = localStorage.getItem('token');
  const body = JSON.stringify({ account_id });

  return fetch(`${BASE_URL}/accounts/getLogs`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body,
  }).then((response) => {
    return response.json().then((json) => {
      return {
        data: json,
        status: response.status,
      };
    });
  });
}
/**
 * @deprecated
 * @param {*} id 
 * @returns 
 */
export function checkSubscription(id) {
  const token = localStorage.getItem('token');

  return fetch(`${BASE_URL}/orders/check-subscription-order-status`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      contractId: id,
    }),
  }).then((response) => {
    return response.json().then((json) => {
      return {
        data: json,
        status: response.status,
      };
    });
  });
}

export function getUserConsumption(id) {
  const token = localStorage.getItem('token');

  return fetch(`${BASE_URL}/contracts/usage-report`, {
    method: 'POST',

    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      contract_id: id,
    }),
  }).then((response) => {
    return response.json().then((json) => {
      return {
        data: json,
        status: response.status,
      };
    });
  });
}

export async function addEmployee(contract_id, employees) {
  const token = localStorage.getItem('token');

  try {
    return fetch(`${BASE_URL}/orders/add-new-employees`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        contract_id,
        employees 
      }),
    }).then((response) => { 
      if (!response.ok) {
        throw new Error('Error ao adicionar funcionário');
      }  
      return response.json().then((json) => {
        return {
          data: json,
          status: response.status,
        };
      });
    });
  } catch (error) {
    console.error('Error adding employee', error);
  }
}

export async function getAccountByCPFAndCNPJ(cpf, cnpj) {
  if (!cpf || !cnpj) {
    throw new Error('CPF and CNPJ are required');
  }
  const token = localStorage.getItem('token');

  try {
    return fetch(`${BASE_URL}/accounts/getByCPFandCNPJ`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ cpf, cnpj }),
    }).then((response) => {
      if (!response.ok) {
        throw new Error('Error ao buscar conta por cpf e cnpj');
      }
      return response.json().then((json) => {
        return {
          data: json,
          status: response.status,
        };
      });
    });
  } catch (error) {
    console.error('Error getting account by cpf and cnpj', error);
  }
}

export async function fireEmployeeContracts(contract_id, terminations) {
  const token = localStorage.getItem('token');

  try {
    return fetch(`${BASE_URL}/orders/terminate-employees`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ contract_id, terminations }),
    }).then((response) => {
      if (!response.ok) {
        throw new Error('Error ao encerrar funcionário');
      }
      return response.json().then((json) => {
        return {
          data: json,
          status: response.status,
        };
      });
    });
  } catch (error) {
    console.error('Error terminating employee', error);
  }
}

export async function reactivateEmployeesByContract(contract_id, reactivations) {
  const token = localStorage.getItem('token');

  try {
    return fetch(`${BASE_URL}/orders/reactivate-employees`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ contract_id, reactivations }),
    }).then((response) => {
      if (!response.ok) {
        throw new Error('Error ao reativar funcionário');
      }
      return response.json().then((json) => {
        return {
          data: json,
          status: response.status,
        };
      });
    });
  } catch (error) {
    console.error('Error reactivate employees by contract', error);
  }
}

export const employeeService = {
  getAccounts,
  checkSubscription,
  getLogs,
  getUserConsumption,
  getAccountByCPFAndCNPJ,
  addEmployee,
  fireEmployeeContracts,
  reactivateEmployeesByContract,
};
