export const styles = (theme) => ({
  root: {
    padding: '0',
    margin: '0',
    width: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  containerWrapper: {
    backgroundColor: theme.palette.background.paper,
    margin: 'var(--spacing-inset-2xs) 0px 0px',
    padding: 'var(--spacing-xs) var(--spacing-3xs) 32px var(--spacing-3xs)',
    width: 'auto',
    minWidth: 'auto',
    border: '1px solid transparent',
    borderRadius: 'var(--spacing-3xs)',
  },
  iconSmall: {
    fontSize: '16px !important',
    margin: '0px',
  },
  rowSpacer: {
    margin: 'var(--spacing-stack-xs) 0px 0px 0px',
  },
  columnSpacer: {
    margin: '0px',
    padding: '0px var(--spacing-stack-sm) 0px 0px',
  },
});