import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { Divider } from '@material-ui/core';

const styles = (theme) => ({
  popper: {
    margin: 0,
    padding: 0,
    backgroundColor: 'transparent',
    zIndex: theme.zIndex.floatMenu,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.primary.neutral100} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.primary.neutral100} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.primary.neutral100} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.primary.neutral100}`,
      },
    },
  },
  menu: {
    margin: 0,
    padding: 0,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 8px 30px -11px #00000059',
    borderRadius: 12,
  },
  menuItem: {
    margin: 0,
    padding: 'var(--spacing-3xs) var(--spacing-4xs)',
  },
  menuItemText: {
    color: 'var(--color-brand-primary-800)',
    fontFamily: 'Sua dr consulta',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '15.4px',
    margin: 0,
    padding: 0,
    whiteSpace: 'nowrap',
  },
  menuItemIcon: {
    color: 'var(--color-brand-primary-800)',
    fontSize: '24px',
    margin: 0,
    padding: '0px 8px 0px 0px',
  },
  arrow: {
    position: 'absolute',
    color: theme.palette.primary.neutral100,
    backgroundColor: 'transparent',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
});

// enumeração para os itens do menu
export const MENU_ACTIONS = {
  CLOSE: 'close',
  INACTIVATE: 'inactivate',
  REACTIVATE: 'reactivate',
};

function ListItemLink(props) {
  return <ListItem button component='button' {...props} />;
}

function FloatMenuAction({ classes, open, row, anchorEl, onMenuItemClick }) {
  const [arrowRef, setArrowRef] = React.useState(null);
  const [isOpened, setIsOpened] = React.useState(open);

  React.useEffect(() => {
    setIsOpened(open);
  }, [open]);

  const processMenuClick = (event, action) => {
    setIsOpened(false);
    onMenuItemClick(event, action, row);
  };

  const handleArrowRef = (node) => {
    setArrowRef(node);
  };

  return (
    <Popper
      id={`action_${row.id}`}
      open={isOpened}
      anchorEl={anchorEl}
      disablePortal
      className={classes.popper}
      modifiers={{
        flip: {
          enabled: true,
        },
        arrow: {
          enabled: true,
          element: arrowRef,
        },
      }}
    >
      <span className={classes.arrow} ref={handleArrowRef} />
      <List component='nav' className={classes.menu}>
        <ListItem
          button
          component='button'
          className={classes.menuItem}
          onClick={(event) => processMenuClick(event, MENU_ACTIONS.CLOSE)}
          style={{ display: 'none'}}
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <Icon>person</Icon>
          </ListItemIcon>
          <Typography className={classes.menuItemText}>
            Acessar cadastro
          </Typography>
        </ListItem>
        <Divider />
        <ListItem
          button
          component='button'
          className={classes.menuItem}
          onClick={(event) => processMenuClick(event, MENU_ACTIONS.INACTIVATE)}
          disabled={row.has_dismissed}
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <Icon>cancel</Icon>
          </ListItemIcon>
          <Typography className={classes.menuItemText}>Inativar</Typography>
        </ListItem>
        <Divider />
        <ListItem
          button
          component='button'
          className={classes.menuItem}
          onClick={(event) => processMenuClick(event, MENU_ACTIONS.REACTIVATE)}
          disabled={!row.has_dismissed}
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <Icon>rotate_left</Icon>
          </ListItemIcon>
          <Typography className={classes.menuItemText}>Reativar</Typography>
        </ListItem>
      </List>
    </Popper>
  );
}

FloatMenuAction.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  row: PropTypes.object,
  anchorEl: PropTypes.object,
  onMenuItemClick: PropTypes.func,
};

FloatMenuAction.defaultProps = {
  open: false,
  row: {},
  anchorEl: null,
  onMenuItemClick: () => {},
};

export default withStyles(styles)(FloatMenuAction);
