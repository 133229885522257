import { BASE_URL } from "../config/env";

export const employeeService = {
  getAccounts,
  checkSubscription,
  getLogs,
  getUserConsumption,
};

export function getAccounts(id) {
  const token = localStorage.getItem('token');

  return fetch(`${BASE_URL}/accounts/getAccountsByContract`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      id: id,
    }),
  }).then((response) => {
    return response.json().then((json) => {
      return {
        data: json,
        status: response.status,
      };
    });
  });
}

export function getLogs(account_id) {
  const token = localStorage.getItem('token');
  const body = JSON.stringify({ account_id });

  return fetch(`${BASE_URL}/accounts/getLogs`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body,
  }).then((response) => {
    return response.json().then((json) => {
      return {
        data: json,
        status: response.status,
      };
    });
  });
}
/**
 * @deprecated
 * @param {*} id 
 * @returns 
 */
export function checkSubscription(id) {
  const token = localStorage.getItem('token');

  return fetch(`${BASE_URL}/orders/check-subscription-order-status`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      contractId: id,
    }),
  }).then((response) => {
    return response.json().then((json) => {
      return {
        data: json,
        status: response.status,
      };
    });
  });
}

export function getUserConsumption(id) {
  const token = localStorage.getItem('token');

  return fetch(`${BASE_URL}/contracts/usage-report`, {
    method: 'POST',

    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      contract_id: id,
    }),
  }).then((response) => {
    return response.json().then((json) => {
      return {
        data: json,
        status: response.status,
      };
    });
  });
}
