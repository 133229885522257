import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { Grid, Typography, IconButton, Icon } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ChevronRightRounded from '@material-ui/icons/ChevronRightRounded.js';

import Button from '../../../../../components/ui/button/Button.js';
import { SUCCESS, DEFAULT } from '../../../../../constants/ActionTypes';

const styles = (theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  paper: {
    width: '100%',
    maxHeight: 435,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: 'var(--color-neutral-800)',
  },
  dialogContent: {
    width: '100%',
    maxWidth: '310px',
  },
  dialogSubTitle: {
    color: theme.palette.primary.black,
    fontFamily: theme.typography.fontFamily,
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '35.2px',
    textAlign: 'left',
    textTransform: 'none',
    margin: 'var(--spacing-2xs) 0px 0px 0px',
  },
  dialogContentEmployees: {
    margin: '0px 0px var(--spacing-2xs)',
    overflow: 'scroll',
    maxHeight: '100px',
  },
  employeeInfo: {
    color: theme.palette.primary.black,
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'left',
    textTransform: 'none',
    margin: '0px 0px var(--spacing-3xs) 0px',
    width: '100%',
    maxWidth: '310px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  dialogWarningBanner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: 'var(--color-feedback-warning-100)',
    padding: 'var(--spacing-3xs)',
    borderRadius: 'var(--spacing-5xs)',
    border: '1px solid var(--color-feedback-warning-900)',
    margin: '0px 0px',
    width: '100%',
  },
  dialogWarningBannerIcon: {
    fontSize: '32px',
    color: 'var(--color-feedback-warning-900)',
  },
  dialogWarningBannerText: {
    color: 'var(--color-feedback-warning-900)',
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
    textAlign: 'left',
    textTransform: 'none',
    margin: '0px 0px 0px var(--spacing-4xs)',
  },
  dialogCancelBanner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.primary.paper,
    padding: 0,
    borderRadius: 'var(--spacing-5xs)',
    border: 'none',
    margin: '0px 0px',
    width: '100%',
  },
  dialogCancelBannerText: {
    color: 'var(--color-neutral-800)',
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'left',
    textTransform: 'none',
    margin: '0px',
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
});

export const ConfirmationDialogBody = withStyles(styles)(
  ({ classes, employees, message }) => {
    if (!employees || employees.length === 0) {
      return null;
    }

    return (
      <div className={classes.dialogContent}>
        <Grid container direction='column'>
          <Grid
            item
            xs={12}
            className={classes.dialogContentEmployees}
          >
            {employees.map((employee, index) => (
              <Typography variant='body1' className={classes.employeeInfo} key={index}>
                {employee.cpf} | {employee.name}
              </Typography>
            ))}
          </Grid>
        </Grid>
        <Grid container direction='column'>
          <Grid item xs={12}>
            <div className={classes.dialogWarningBanner}>
              <Icon className={classes.dialogWarningBannerIcon}>
                emergency_home
              </Icon>
              <Typography
                variant='body1'
                className={classes.dialogWarningBannerText}
              >
                {message}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
);

// ConfirmationDialogBody.propTypes = {
//   employees: PropTypes.array.isRequired,
//   message: PropTypes.string.isRequired,
// };

export const ConfirmationDialogBodyCancel = withStyles(styles)(
  ({ classes, message }) => {
    return (
      <div className={classes.dialogContent}>
        <Grid container direction='column'>
          <Grid item xs={12}>
            <div className={classes.dialogCancelBanner}>
              <Typography
                variant='body1'
                className={classes.dialogCancelBannerText}
              >
                {message}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
);


class ConfirmationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: {
        open: this.props.open,
        disableBackdropClick: false,
        disableEscapeKeyDown: false,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open) {
      this.setState({ dialog: { open: nextProps.open } });
    }
  }

  handleClose() {
    this.setState({ dialog: { open: false } });
    this.props.onClose(DEFAULT);
  }

  handleCancel = () => {
    this.props.onClose(DEFAULT);
  };

  handleOk = () => {
    this.props.onClose(SUCCESS);
  };

  render() {
    const { classes, title, subTitle, content, open } = this.props;
    const { dialog } = this.state;

    return (
      <Dialog
        open={open}
        disableBackdropClick={dialog.disableBackdropClick}
        disableEscapeKeyDown={dialog.disableEscapeKeyDown}
        maxWidth='xs'
        fullWidth={true}
        aria-labelledby='confirmation-dialog-title'
        className={classes.root}
      >
        <DialogTitle id='confirmation-dialog-title'>
          <Grid container justify='space-between' alignItems='center'>
            <Grid item>
              <Typography variant='h6'>{title || ' '}</Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label='Close'
                className={classes.closeButton}
                onClick={() => this.handleClose()}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container justify='space-between' alignItems='center'>
            <Grid item>
              <Typography variant='h6' className={classes.dialogSubTitle}>
                {subTitle}
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.paper}>{content}</DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            isDisabled={false}
            onClick={() => this.handleCancel()}
            style={{ color: '#1A1A1A', borderColor: '#1A1A1A' }}
            variant='link'
            size='sm'
          >
            <CloseIcon />
            Fechar
          </Button>
          <Button
            variant='primary'
            size='md'
            color='primary'
            onClick={() => this.handleOk()}
            endIcon={<ChevronRightRounded />}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
};

ConfirmationDialog.defaultProps = {
  open: false,
  onClose: () => {},
};

export default withStyles(styles)(ConfirmationDialog);
