export default {
  palette: {
    // https://material-ui.com/customization/themes/#palette
    primary: {
      light: '#61C1FF',
      main: '#3A10E0',
      dark: '#0064AE',
      brand100: '#FCFBFE',
      brand200: '#F2F1FD',
      brand300: '#E2DFFA',
      brand400: '#CBC7F6',
      brand500: '#ADA7F1',
      brand600: '#8980EB',
      brand700: '#5F53E4',
      brand800: '#3A10E0',
      brand900: '#071060',
      neutral100: "#FFFFFF",
    },
    secondary: {
      main: '#EC213F'
    },
    error: {
      main: '#EC213F'
    },
    background: {
      paper: "#fff",
      default: "#F6F6F6",
    },
    accent1Color: {
      main: '#abab00'
    },
    success: '#64C160'
  },
  status: {
    success: '#64C160',
    warning: '#F2994A',
    error: '#EC213F'
  },
  typography: {
    // https://material-ui.com/customization/themes/#typography
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Inter',
      'sans-serif'
    ].join(','),
    h1: {
      fontWeight: 400,
      fontSize: 24,
      color: 'rgba(56, 68, 89, 0.6)',
      lineHeight: 1.75
    },
    body1: {
      fontFamily: 'Inter',
      fontWeight: '400',
      fontSize: '14px',
      color: '#1A1A1A',
    },
    body2: {
      fontFamily: 'Inter',
      fontWeight: '600',
      fontSize: '14px',
      color: '#1A1A1A',
    },
    button: {
      fontWeight: 700
    }
  },
  overrides: {
    //   https://material-ui.com/customization/overrides/#overrides
    // Name of the component ⚛️
    MuiExpansionPanel: {
      // The properties to apply
      root: {
        borderRadius: 4,
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 16,
        marginBottom: 16,
        '&:before': {
          display: 'none'
        },
        '&.green-info': {
          borderLeft: '4px solid rgba(77, 184, 72, 0.87)'
        },
        '&.red-info': {
          borderLeft: '4px solid rgba(233, 0, 34, 0.87)'
        },
        '&.orange-info': {
          borderLeft: '4px solid rgba(242, 153, 74, 0.87)'
        },
        '&.blue-info': {
          borderLeft: '4px solid rgba(0, 145, 224, 0.87)'
        }
      }
    },
    MuiTypography: {
      root: {
        '&.green-info': {
          color: '#143E47',
          backgroundColor: '#E5FAF4',
        },
        '&.red-info': {
          color: "#89024C",
          backgroundColor: "#FFE0F1"
        },
        '&.orange-info': {
          color: 'rgba(242, 153, 74, 0.87)'
        },
        '&.blue-info': {
          color: '#3A10E0'
        },
        '&.status-success': {
          color: '#143E47',
          backgroundColor: '#E5FAF4',
        },
        '&.status-info': {
          color: '#646464',
          backgroundColor: '#F2F2F0',
        },
        '&.status-warning': {
          color: "#93370D",
          backgroundColor: "#FEF0C7"
        },
        '&.status-error': {
          color: '#89024C',
          backgroundColor: '#FFE0F1'
        },
      }
    }
  },
  zIndex: {
    modal: 1300,
    floatMenu: 1260,
  }
};
