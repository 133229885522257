import React from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import configureStore, { history } from './store';
import { ConnectedRouter } from 'connected-react-router';
import App from './containers/App';
import { FeatureFlagsProvider } from '~/contexts/FeatureFlagsContext';

export const store = configureStore();

const MainApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
    <FeatureFlagsProvider>
      <Router history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </Router>
    </FeatureFlagsProvider>
    </ConnectedRouter>
  </Provider>
);

export default MainApp;
