import React from "react";
import { Button, CircularProgress } from '@material-ui/core';

/**
 * @param {string} size - The size of the button ('sm', 'md', 'lg').
 * @returns {Object} - Styles for the button based on the size.
 */
function sizeBuilder(size) {
  if (size === 'sm') return {
    minWidth: '122px',
    fontSize: '14px',
    height: '36px',
    padding: '8px',
  };
  if (size === 'md') return {
    minWidth: '130px',
    height: '52px',
    borderRadius: '6px',
    fontSize: '16px',
    padding: '6px',
  };
  if (size === 'lg') return {
    minWidth: '155px',
    height: '68px',
    fontSize: '18px', // Validar quando precisar usar o botão
    padding: '24px',
    borderRadius: '8px',
  };
}

/**
 * @param {Object} params - Parameters for determining button variant styles.
 * @param {string} variant - The variant of the button.
 * @param {Object} colors - Color scheme based on the button state.
 * @param {boolean} isLoading - Whether the button is in a loading state.
 * @param {boolean} isDanger - Whether the button is in a danger state.
 * @returns {Object} - Styles for the button based on the variant.
 */
function variantBuilder({ variant, colors, isLoading, isDanger }) {
  if (['primary'].includes(variant)) return {
    backgroundColor: colors.primary,
    color: colors.secondary,
    border: 'none',
    border: '1px solid ' + (isDanger ? colors.secondary : colors.primary),
  };
  if (['secondary'].includes(variant)) return {
    backgroundColor: '#FFFFFF',
    color: colors.primary,
    border: '1px solid ' + colors.primary,
  };
  if (['link'].includes(variant)) return {
    border: 'none',
    borderRadius: 0,
    color: colors.primary,
    borderBottom: '1px solid ' + colors.primary,
  };

  if (isLoading) {
    console.error('Tipo link não pode conter loading');
    return {
      backgroundColor: '#FFFFFF',
      color: '#FFFFFF',
      border: 'none',
    };
  }
}

/**
 * @param {Object} params - Parameters for determining color scheme based on state.
 * @param {string} variant - The variant of the button.
 * @param {boolean} isDisabled - Whether the button is disabled.
 * @param {boolean} isDanger - Whether the button is in a danger state.
 * @returns {Object} - Color scheme based on the button state.
 */
function takeColor({ variant, isDisabled, isDanger }) {
  const colors = {
    primary: '#3A10E0',
    secondary: '#FFFFFF',
  };

  if (isDanger) {
    if (variant === 'primary') {
      colors.primary = '#FFE0F1';
      colors.secondary = '#89024C';
    } else {
      colors.primary = '#89024C';
      colors.secondary = '#FFE0F1';
    }
  }

  if (isDisabled) {
    if (variant === 'primary') {
      colors.primary = '#AFAFAF';
      colors.secondary = '#404040';
    } else {
      colors.primary = '#404040';
      colors.secondary = '#FFFFFF';
    }
  }

  return colors;
}

/**
 * Custom button component with configurable properties.
 * @param {Object} props - The props object.
 * @param {('primary'|'secondary'|'link')} [props.variant='primary'] - The variant of the button. Can be 'primary', 'secondary', or 'link'.
 * @param {('sm'|'md'|'lg')} [props.size='sm'] - The size of the button.
 * @param {function} [props.onClick] - The click handler function for the button.
 * @param {boolean} [props.isDisabled=false] - Whether the button is disabled.
 * @param {boolean} [props.isDanger=false] - Whether the button is in a danger state.
 * @param {boolean} [props.isLoading=false] - Whether the button is in a loading state.
 * @param {React.ReactNode} props.children - The content to be displayed inside the button.
 * @param {React.ReactNode} [props.endIcon] - The icon to be displayed at the end of the button.
 * @param {React.ReactNode} [props.startIcon] - The icon to be displayed at the start of the button.
 * @param {Object} [props.style] - Additional styles for the button.
 * @returns {JSX.Element} - A button component with specified properties.
 */
const CustomButton = ({
  variant = 'primary',
  size = 'sm',
  onClick,
  isDisabled = false,
  isDanger = false,
  isLoading = false,
  children,
  endIcon,
  startIcon,
  style,
  type,
}) => {
  const sizeStyle = sizeBuilder(size);
  const colors = takeColor({ variant, isDisabled, isDanger, isLoading });
  const variantSyle = variantBuilder({ variant, colors, isLoading, isDanger });

  const styleCustom = {
    textTransform: 'none',
    fontFamily: "Sua dr consulta",
    fontWeight: 400,
    borderRadius: '6px',
    alignItems: 'center',
    textAlign: 'center',
    gap: '10px',
    fontSize: 14,
    display: 'flex',
    justifyContent: endIcon ? 'space-between' : 'center',
    ...sizeStyle,
    ...variantSyle,
    ...style
  };



  const iconStyle = {
    width: '16px',
    height: '16px',
    display: 'flex',
    alignItems: 'center',
    paddingRiht: '0px',
    paddingLeft: '0px',
    marginLeft: '4px',
    marginRight: '10px',
  }


  
  const handleClick = (event) => {
    if (!isDisabled && !isLoading && onClick) {
      onClick(event);
    }
  };

  const loadingColor = variant === 'primary' ? '#FFFFFF' : '#3A10E0';

  return (
    <Button
      onClick={handleClick}
      disabled={isDisabled || isLoading}
      style={styleCustom}
      type={type || 'button'}
    >
      {isLoading ? (
        <CircularProgress
          size={15}
          mode="indeterminate"
          style={{ color: loadingColor }}
        />
      ) : (
        <React.Fragment>
            {startIcon && (
            <div style={iconStyle} >
              {startIcon}
            </div>
          )}
          <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '4px' }} >
            {children}
          </div>
          {endIcon && (
            <div style={iconStyle} >
              {endIcon}
            </div>
          )}
        </React.Fragment>
      )}
    </Button>
  );
};

export default CustomButton;
