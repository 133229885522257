import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Grid,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Typography,
  Checkbox,
} from '@material-ui/core';
import Pagination from 'material-ui-flat-pagination';
import { EnhancedTableHeadCell } from '../../../../../../util/EnhancedTableHeadCell';
import { exportToCSV, getCellValue } from './utils';
import TableCellAction from './TableCellAction';

const ListEmployeeTable = ({
  data,
  columns,
  loading,
  pageSize,
  onSelected,
  onActionMenuClick,
}) => {
  const defaultSortColumn = columns[3].field; // update_at
  const defaultSortDirection = 'desc';
  const [selected, setSelected] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortColumn, setSortColumn] = useState(defaultSortColumn);
  const [sortDirection, setSortDirection] = useState(defaultSortDirection);
  const [tableData, setTableData] = useState([]);

  React.useEffect(() => {
    if (loading) {
      return;
    }
    const currentPage = 0;
    const sortColumn = defaultSortColumn;
    const sortDirection = defaultSortDirection;

    setCurrentPage(currentPage);
    setSortColumn(sortColumn);
    setSortDirection(sortDirection);

    setTableData(
      _.chain(data)
        .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
        .orderBy([sortColumn], [sortDirection])
        .value()
    );
  }, [data]);

  React.useEffect(() => {
    if (loading) {
      return;
    }
    setTableData(
      _.chain(data)
        .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
        .orderBy([sortColumn], [sortDirection])
        .value()
    );
  }, [currentPage, sortColumn, sortDirection]);


  const handlePageChange = (page) => {
    resetEditFlags();
    setSelected([]);
    onSelected([]);
    setCurrentPage(page);
  };

  const handleSort = (column) => {
    const direction =
      column === sortColumn
        ? sortDirection === 'asc'
          ? 'desc'
          : 'asc'
        : 'asc';
    setSortColumn(column);
    setSortDirection(direction);
  };

  const getSelected = (personId) => selected.indexOf(personId) !== -1;

  const getSelectedEmployees = (selectedEmployeeIds) => {
    const employees = tableData
      .filter((row) => selectedEmployeeIds.includes(row.personId))
      .map((employee) => ({
        cpf: employee.personId,
        name: employee.person_name,
        company_cnpj: employee.company_cnpj,
        account_id: employee.account_id,
        has_dismissed: employee.has_dismissed,
        status: employee.status,
      }));

    onSelected(employees);
  };

  const resetEditFlags = () => {
    setTableData((prevTableData) => {
      return prevTableData.map((row) => {
        return {
          ...row,
          action: {
            ...row.action,
            isEdit: false,
          },
        };
      });
    });
  };

  const handleSelectAllClick = (event) => {
    const newSelected = [];
    if (event.target.checked) {
      newSelected.push(...tableData.map((row) => row.personId));
    }

    setSelected(newSelected);

    if (newSelected.length === 0) {
      resetEditFlags();
    }

    getSelectedEmployees(newSelected);
  };

  const handleActionClick = (event, rowId) => {
    setTableData((prevTableData) => {
      return prevTableData.map((row) => {
        return {
          ...row,
          action: {
            ...row.action,
            isEdit: row.rowId === rowId ? !row.action.isEdit : false,
          },
        };
      });
    });
  };

  const handleSelectClick = (personId, rowId, rowAction) => {
    let newSelected = [];
    const selectedIndex = selected.indexOf(personId);

    if (selectedIndex === -1) {
      newSelected.push(...selected, personId);
    } else if (!rowAction) {
      newSelected.push(...selected.filter((id) => id !== personId));
    } else if (rowAction) {
      newSelected.push(...selected);
    }

    setSelected(newSelected);
    getSelectedEmployees(newSelected);
  };

  const handleClick = (event, personId, rowId) => {
    const row = event.target.closest('tr');
    const column = event.target.closest('td');

    if (!row || !column) {
      return;
    }

    const rowAction = column.getAttribute('data-row-action') === 'true';

    if (!rowAction) {
      resetEditFlags();
    }

    handleSelectClick(personId, rowId, rowAction);
  };

  const isCheckboxIndeterminate = () => {
    return selected.length > 0 && selected.length < tableData.length;
  };

  const isCheckboxChecked = () => {
    return tableData.length > 0 && selected.length === tableData.length;
  };

  return (
    <Paper
      style={{
        paddingTop: 8,
        paddingLeft: 8,
        paddingRight: 8,
        border: '1px solid #AFAFAF',
      }}
    >
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {loading && (
            <Grid container justify='center' style={{ padding: 100 }}>
              <CircularProgress style={{ margin: 50, color: '#005DF9' }} />
            </Grid>
          )}
          {!loading && (
            <Grid container>
              <Grid item xs={12} style={{ margin: '0px 20px' }}>
                <Table padding='none'>
                  <TableHead>
                    <TableRow>
                      <TableCell padding='checkbox'>
                        <Checkbox
                          indeterminate={isCheckboxIndeterminate()}
                          checked={isCheckboxChecked()}
                          onChange={handleSelectAllClick}
                        />
                      </TableCell>
                      {columns.map((column) => (
                        <EnhancedTableHeadCell
                          key={column.id}
                          order={sortDirection}
                          orderBy={sortColumn}
                          onRequestSort={() => handleSort(column.field)}
                          labelId={column.id}
                          labelName={column.label}
                          alignment='left'
                        />
                      ))}
                      <TableCell
                        padding='checkbox'
                        style={{
                          color: '#707070',
                          fontWeight: '700',
                          fontSize: '16px',
                          fontFamily: 'Sua dr consulta',
                          padding: '.2rem',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        Ações
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.map((row, index) => {
                      const isSelected = getSelected(row.personId);
                      return (
                        <TableRow
                          hover
                          onClick={(event) =>
                            handleClick(event, row.personId, row.rowId)
                          }
                          role='checkbox'
                          aria-checked={isSelected}
                          tabIndex={-1}
                          selected={isSelected}
                          key={row.rowId}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? '#ffffff' : '#F6F6F6',
                          }}
                        >
                          <TableCell padding='checkbox'>
                            <Checkbox checked={isSelected} />
                          </TableCell>
                          {columns.map((column) => (
                            <TableCell
                              style={column.columnStyle}
                              key={column.id}
                              data-row-id={row.rowId}
                              data-row-action={false}
                            >
                              {getCellValue(row, column)}
                            </TableCell>
                          ))}
                          <TableCellAction
                            row={row}
                            onClick={handleActionClick}
                            onActionMenuClick={onActionMenuClick}
                          />
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <Grid
                  container
                  direction='row'
                  justify='space-between'
                  style={{ marginBottom: 16, marginTop: 16 }}
                >
                  <Grid item xs={6}>
                    <Pagination
                      size='small'
                      limit={pageSize}
                      offset={currentPage * pageSize}
                      total={data.length}
                      onClick={(e, offset) =>
                        handlePageChange(offset / pageSize)
                      }
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    alignItems='flex-start'
                    justify='flex-end'
                  >
                    {data.length > 0 && (
                      <Button
                        onClick={() => exportToCSV(data, columns)}
                        style={{
                          width: 100,
                          borderColor: 'blue',
                          color: 'blue',
                        }}
                        variant='outlined'
                      >
                        Exportar
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction='column'
                  style={{ marginBottom: 16, marginTop: 16 }}
                >
                  <Grid item>
                    <Typography variant='caption' style={{ color: '#707070' }}>
                      Total de vidas: {data.length}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

ListEmployeeTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  pageSize: PropTypes.number,
  onSelected: PropTypes.func.isRequired,
  onActionMenuClick: PropTypes.func.isRequired,
};

ListEmployeeTable.defaultProps = {
  loading: false,
  pageSize: 10,
  onSelected: () => {},
  onActionMenuClick: () => {},
};

export default ListEmployeeTable;
