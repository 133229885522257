import React, { Component, Fragment } from 'react';
import { FormControl, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Select from '../../../../../../components/ui/select';
import TextField from '../../../../../../components/ui/TextField';

import { utils } from '../../../../utils';
import { pjService } from '../../../../../../services/pjService';

import { styles } from '../styles';

function UserForm(props) {
  const {
    handleInputChange,
    validate,
    currentUser,
    index,
    loading,
    confirmation,
    personFetched,
    classes,
  } = props;
  return (
    <Grid
      container
      direction='column'
      justify='flex-start'
      alignItems='flex-start'
      spacing={0}
      className={classes.root}
    >
      <Grid
        container
        item
        direction='row'
        spacing={0}
        className={classes.rowSpacer}
      >
        <hr className={classes.lineSeparator} />
      </Grid>
      <Grid
        container
        item
        direction='row'
        justify='flex-start'
        alignItems='flex-start'
        spacing={0}
        className={classes.rowSpacer}
      >
        <Grid item xs={4} md={4} className={classes.columnSpacer}>
          <TextField
            small={true}
            name='cpf'
            id={'frm_user_cpf' + index}
            label='CPF'
            required={true}
            value={currentUser.user.cpf}
            onChange={(e) => handleInputChange(e, index)}
            onBlur={(e) => validate(e, index)}
            error={currentUser.touched.cpf && currentUser.errors.cpf !== ''}
            type='text'
            disabled={loading || confirmation}
            formInputHelper={{
              title: 'CPF',
              text: currentUser.errors.cpf,
              showIcon: true,
              isShow: currentUser.touched.cpf && currentUser.errors.cpf !== '',
            }}
          />
        </Grid>
        <Grid item xs={8} md={8} className={classes.columnSpacer}>
          <TextField
            small={true}
            name='nome'
            id={'frm_user_nome' + index}
            label='Nome'
            required={true}
            value={currentUser.user.nome}
            onChange={(e) => handleInputChange(e, index)}
            onBlur={(e) => validate(e, index)}
            error={currentUser.touched.nome && currentUser.errors.nome !== ''}
            type='text'
            disabled={loading || confirmation}
            formInputHelper={{
              title: 'Nome',
              text: currentUser.errors.nome,
              showIcon: true,
              isShow:
                currentUser.touched.nome && currentUser.errors.nome !== '',
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction='row'
        justify='flex-start'
        alignItems='flex-start'
        spacing={0}
        className={classes.rowSpacer}
      >
        <Grid item xs={4} md={4} className={classes.columnSpacer}>
          <FormControl fullWidth margin='none'>
            <Select
              name='sexo'
              id={'frm_user_sexo' + index}
              label='Gênero'
              value={currentUser.user.sexo}
              onChange={(e) => {
                handleInputChange(e, index);
                validate(e, index);
              }}
              onBlur={(e) => {
                validate(e, index);
              }}
              isDisabled={loading || confirmation}
              error={currentUser.touched.sexo && currentUser.errors.sexo !== ''}
              errorMessage={currentUser.touched.sexo && currentUser.errors.sexo}
              options={[
                { value: 'M', name: 'Masculino' },
                { value: 'F', name: 'Feminino' },
              ]}
            ></Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} md={4} className={classes.columnSpacer}>
          <TextField
            small={true}
            name='data_nascimento'
            id={'frm_user_data_nascimento' + index}
            label='Data de Nascimento'
            value={currentUser.user.data_nascimento}
            onChange={(e) => handleInputChange(utils.capDate(e), index)}
            onBlur={(e) => validate(e, index)}
            error={currentUser.errors.data_nascimento !== ''}
            type='date'
            disabled={loading || confirmation}
            formInputHelper={{
              title: 'Data de Nascimento',
              text: currentUser.errors.data_nascimento,
              showIcon: true,
              isShow: currentUser.errors.data_nascimento !== '',
            }}
          />
        </Grid>
        <Grid item xs={4} md={4} className={classes.columnSpacer}>
          <TextField
            small={true}
            name='telefone'
            id={'frm_user_telefone' + index}
            label='Telefone'
            required={true}
            value={currentUser.user.telefone}
            onChange={(e) => handleInputChange(e, index)}
            onBlur={(e) => validate(e, index)}
            error={
              currentUser.touched.telefone && currentUser.errors.telefone !== ''
            }
            type='text'
            disabled={loading || confirmation}
            formInputHelper={{
              title: 'Telefone',
              text: currentUser.errors.telefone,
              showIcon: true,
              isShow:
                currentUser.touched.telefone &&
                currentUser.errors.telefone !== '',
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction='row'
        justify='flex-start'
        alignItems='flex-start'
        spacing={0}
        className={classes.rowSpacer}
      >
        <Grid item xs={4} md={4} className={classes.columnSpacer}>
          <TextField
            small={true}
            name='email'
            id={'frm_user_email' + index}
            label='Email'
            required={true}
            value={currentUser.user.email}
            onChange={(e) => handleInputChange(e, index)}
            onBlur={(e) => validate(e, index)}
            error={currentUser.touched.email && currentUser.errors.email !== ''}
            type='text'
            disabled={loading || confirmation}
            formInputHelper={{
              title: 'Email',
              text: currentUser.errors.email,
              showIcon: true,
              isShow:
                currentUser.touched.email && currentUser.errors.email !== '',
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

class Users extends Component {
  constructor(props) {
    super(props);

    const { replicate, financialResp } = this.props;

    const nome = replicate ? financialResp.nome : '';
    const sexo = replicate ? financialResp.sexo : '';
    const cpf = replicate ? financialResp.cpf : '';
    const data_nascimento = replicate ? financialResp.data_nascimento : '';
    const telefone = replicate ? financialResp.telefone: '';
    const email = replicate ? financialResp.email : '';

    this.state = {
      qtdUsuarios: 1,
      users: [
        {
          user: {
            nome,
            cpf,
            sexo,
            data_nascimento,
            telefone,
            email,
          },
          errors: {
            nome: !nome ? 'Por favor, insira um nome.' : '',
            cpf: !cpf ? 'Por favor, insira um CPF.' : '',
            sexo: !sexo ? 'Por favor, insira um sexo.': '',
            data_nascimento: '',
            telefone: !telefone ? 'Por favor, insira o telefone.' : '',
            email: !email ? 'Por favor, insira um e-mail.' : '',
          },
          touched: {
            nome: false,
            sexo: false,
            cpf: false,
            data_nascimento: false,
            telefone: false,
            email: false,
          },
          personFetched: false,
        },
      ],
      erro: '',
      loading: false,
    };
  }

  componentDidMount() {
    this.props.setActiveComponentStep(this);
    if (this.props.users[0] !== undefined) {
      const users = [];
      for (const user of this.props.users) {
        const newUser = {
          user: {
            nome: user.user.nome,
            sexo: user.user.sexo,
            cpf: user.user.cpf,
            data_nascimento: user.user.data_nascimento,
            telefone: user.user.telefone,
            email: user.user.email,
          },
          errors: {
            nome:
              user.user.nome === null || user.user.nome === ''
                ? 'Por favor, insira o nome.'
                : '',
            cpf:
              user.user.cpf === null || user.user.cpf === ''
                ? 'Por favor, insira o CPF.'
                : '',
            sexo:
              user.user.sexo === null || user.user.sexo === ''
                ? 'Por favor, insira o sexo.'
                : '',
            data_nascimento: '',
            telefone: '',
            email:
              user.user.email === null || user.user.email === ''
                ? 'Por favor, insira o email.'
                : '',
          },
          touched: {
            nome: true,
            sexo: true,
            cpf: true,
            data_nascimento: true,
            telefone: true,
            email: true,
          },
        };
        users.push(newUser);
      }

      this.setState({
        users,
        qtdUsuarios: this.props.users.length,
      });

      this.props.currentComponentStepChange(this.inValidateForm(users));
    } else {
      this.props.currentComponentStepChange(this.inValidateForm(this.state.users));
    }
  }

  updateCurrentState() {
    this.props.setInputs(this.state.users);
  }

  handleInputChange = (event, index) => {
    const users = this.state.users;
    const usuario = users[index];
    let value = event.target.value;

    if (event.target.name === 'telefone') {
      value = utils.handleCellphone(value);
    }

    if (event.target.name === 'cpf') {
      value = utils.handleCPF(value);
    }

    usuario.user[event.target.name] = value;
    users[index] = usuario;

    this.setState({ users });
  };

  handleCpfFetch = (response, index) => {
    if (response.success) {
      const person = response.response;
      person.birth_date = !person.birth_date
        ? ''
        : (person.birth_date = person.birth_date.slice(0, 10));

      if (person.phone) {
        person.phone =
          person.phone.length > 0
            ? utils.handleCellphone(
                person.phone[0].area_code.split('0')[1] +
                  person.phone[0].number,
              )
            : '';
      } else {
        person.phone = '';
      }

      const newUser = this.state.users[index];
      const users = this.state.users;
      newUser.user.nome = person.name;
      newUser.user.sexo = person.gender;

      newUser.user.email = person.email;
      newUser.errors.email =
        person.email === '' ? users[index].errors.email : '';
      newUser.touched.email = person.email === '' ? false : true;

      newUser.errors.nome = '';
      newUser.errors.sexo = '';
      newUser.touched.nome = true;
      newUser.touched.nome = true;
      newUser.user.data_nascimento = person.birth_date;
      newUser.user.telefone = person.phone;
      newUser.touched.telefone = person.phone !== '';
      newUser.touched.data_nascimento = person.birth_date !== '';
      newUser.errors.telefone =
        person.phone !== '' ? '' : users[index].errors.telefone;
      newUser.errors.data_nascimento =
        person.birth_date !== '' ? '' : users[index].errors.data_nascimento;

      newUser.personFetched = true;
      users[index] = newUser;

      this.setState({
        users,
        loading: false,
      });
      this.validateUserEmail(newUser.user.email, index);
      this.props.currentComponentStepChange(this.inValidateForm(users));
    } else {
      const newUser = this.state.users[index];
      const users = this.state.users;
      newUser.personFetched = false;
      users[index] = newUser;
      this.setState({ users, loading: false });
    }
  };

  validateUserEmail = (email, index) => {
    if (this.props.cadastro) {
      this.setState({ loading: true });

      pjService.getLoginData(email).then((response) => {
        if (response.success) {
          const newUser = this.state.users[index];
          newUser.errors.email =
            'Já existe um usuário cadastrado com este e-mail';
          newUser.touched.email = true;
          const users = this.state.users;
          users[index] = newUser;
          this.setState({ loading: false, users });
        } else {
          this.setState({ loading: false });
        }
      });
    }
  };

  validate = (e, index) => {
    let error = e.target.value ? '' : 'Por Favor, preencha este campo.';
    if (e.target.name === 'cpf') {
      error = utils.validateCpf(e.target.value);
      if (error === '') {
        if (
          this.state.users.filter((user) => user.user.cpf === e.target.value)
            .length > 1
        ) {
          error = 'Você já esta cadastrando um usuário com este CPF.';
        }
        if (error === '') {
          this.setState({ loading: true });
          pjService
            .getByCpf(
              utils.removeNonNumericCharacters(
                `${this.state.users[index].user.cpf}`,
              ),
            )
            .then((response) => this.handleCpfFetch(response, index));
        }
      }
    }

    if (e.target.name === 'telefone') {
      error =
        e.target.value.length >= 14 || e.target.value === ''
          ? ''
          : 'Por favor, insira um telefone válido.';
    }

    if (e.target.type === 'date') {
      error =
        utils.validateDate(e.target.value) || e.target.value === ''
          ? ''
          : 'Por favor, insira uma data válida.';
    }

    if (e.target.name === 'email') {
      error =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          e.target.value,
        )
          ? ''
          : 'Por favor, insira um email válido.';
      if (error === '') {
        if (
          this.state.users.filter((user) => user.user.email === e.target.value)
            .length > 1
        ) {
          error = 'Você já está cadastrando um usuário com este e-mail';
        }
        if (error === '') this.validateUserEmail(e.target.value, index);
      }
    }
    const newUser = this.state.users[index];
    newUser.errors[e.target.name] = error;
    newUser.touched[e.target.name] = true;
    const users = this.state.users;
    users[index] = newUser;

    this.setState({
      users,
    });

    this.props.currentComponentStepChange(this.inValidateForm(users));
  };

  setInitialUsers = (users) => {
    if (this.props.users.length > 0) {
      for (let i = 0; i < this.props.users.length; i++) {
        if (users.length >= i + 1) {
          users[i] = this.props.users[i];
        } else {
          break;
        }
      }
    }

    return users;
  };

  createUserFields = (qtdUsuarios) => {
    let erro = '';
    qtdUsuarios = parseInt(qtdUsuarios);
    if (!qtdUsuarios || qtdUsuarios < 0) {
      qtdUsuarios = 0;
      erro = 'O número mínimo de usuários deve ser 1';
    } else if (qtdUsuarios > 20) {
      qtdUsuarios = 20;
    }

    let users = this.state.users;
    if (qtdUsuarios > this.state.qtdUsuarios) {
      while (users.length < qtdUsuarios) {
        users.push({
          user: {
            nome: '',
            sexo: '',
            cpf: '',
            data_nascimento: '',
            telefone: '',
            email: '',
          },
          errors: {
            nome: 'Por favor, insira um nome.',
            cpf: 'Por favor, insira um CPF.',
            sexo: 'Por favor, insira um sexo.',
            data_nascimento: '',
            telefone: '',
            email: 'Por favor, insira um e-mail.',
          },
          touched: {
            nome: false,
            sexo: false,
            cpf: false,
            data_nascimento: false,
            telefone: false,
            email: false,
          },
        });
      }
      users = this.setInitialUsers(users);
      this.setState({ qtdUsuarios, users, erro });
      this.props.currentComponentStepChange(this.inValidateForm(users));
    } else {
      if (qtdUsuarios < this.state.qtdUsuarios) {
        while (users.length > qtdUsuarios) {
          users.pop();
        }
        users = this.setInitialUsers(users);
        this.setState({ qtdUsuarios, users, erro });
        this.props.currentComponentStepChange(this.inValidateForm(users));
      }
    }
  };

  inValidateForm = (users) => {
    if (this.state.qtdUsuarios < 1) return false;
    let error = users.every((resp) => {
      const { nome, sexo, cpf, data_nascimento, telefone, email } = resp.errors;

      return (!nome && !sexo && !cpf && !data_nascimento && !telefone && !email);
    });

    return !error;
  };

  render() {
    const { users, qtdUsuarios, erro: amountOfUserError, loading } = this.state;
    const { classes, confirmation } = this.props;
    return (
      <Fragment>
        <Grid
          container
          direction='column'
          justify='flex-start'
          alignItems='flex-start'
          spacing={0}
          className={classes.root}
        >
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                name='qtdUsuarios'
                id='qtdUsuarios'
                label='Número de Usuários'
                inputProps={{ min: 1, max: 20, step: 1 }}
                value={qtdUsuarios}
                onChange={(e) => this.createUserFields(e.target.value)}
                error={amountOfUserError !== ''}
                disabled={loading || confirmation}
                type='number'
                formInputHelper={{
                  title: 'Número de Usuários',
                  text: amountOfUserError,
                  showIcon: true,
                  isShow: amountOfUserError !== '',
                }}
              />
            </Grid>
          </Grid>

          {qtdUsuarios > 0 &&
            users.map((user, index) => {
              return (
                <UserForm
                  key={index}
                  handleInputChange={this.handleInputChange}
                  validate={this.validate}
                  currentUser={user}
                  index={index}
                  loading={loading}
                  confirmation={confirmation}
                  personFetched={user.personFetched}
                  classes={classes}
                />
              );
            })}
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Users);
