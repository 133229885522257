import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  FormControl,
  Typography,
  Icon,
  DialogActions,
} from '@material-ui/core';
import { employeeService } from '../../../../../services/employeeService';
import YaloSearchFilter from '../../../../../components/YaloSearchFilter';
import ContainerHeader from '../../../../../components/ContainerHeader/index';

import { utils } from '../../../../routes/utils';
import { SUCCESS } from '../../../../../constants/ActionTypes';

import SelectUi from '../../../../../components/ui/select/index';
import Button from '../../../../../components/ui/button/Button';
import ListEmployeeTable from './ListEmployeeTable';
import { MENU_ACTIONS } from './ListEmployeeTable/FloatMenuAction';
import './index.css';
import CustomizedSnackBar from '../../../../../components/Snackbar/index';
import StatusToggleMenu from './StatusToggleMenu';
import ConfirmationDialog, {
  ConfirmationDialogBody,
} from '../components/ConfirmationDialog';
import { has } from 'lodash';

const STATUS = [
  { VALUE: '', LABEL: 'Todos' },
  { VALUE: 'active', LABEL: 'Ativos' },
  { VALUE: 'inactive', LABEL: 'Desligados' },
];

const defaultColumnStyle = {
  color: '#707070',
  fontFamily: 'Sua dr consulta',
  fontWeight: 400,
  fontSize: '14px',
};

const columns = [
  {
    id: 0,
    field: 'hasDismissedText',
    columnStyle: defaultColumnStyle,
    label: 'Status',
    format: 'text',
  },
  {
    id: 1,
    field: 'cpf',
    columnStyle: { ...defaultColumnStyle, letterSpacing: '0.75px' },
    label: 'CPF',
    format: 'cpf',
  },
  {
    id: 2,
    field: 'person_name',
    columnStyle: defaultColumnStyle,
    label: 'Nome',
    format: 'text',
  },
  {
    id: 3,
    field: 'update_at',
    columnStyle: defaultColumnStyle,
    label: 'Atualizado em',
    format: 'date',
  },
];

class EmployeesConsulting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasExistingContract: !!this.props.selectedContract.id,
      employees: [],
      filteredEmployees: [],
      selected: [],
      loading: false,
      search: '',
      statusFilter: '',
      selectedRows: [],
      snackBar: {
        open: false,
        message: '',
        variant: 'success',
      },
      statusToggleMenu: {
        open: false,
        anchorEl: null,
      },
      AddNewEmployee: {
        success: false,
        message: '',
        error: false,
        flashed: false,
        ...this.props.location.state,
      },
      confirmDialogReactivate: {
        open: false,
        message: '',
        employees: [],
      },
      confirmDialogInactivate: {
        open: false,
        message: '',
        employees: [],
      },
    };
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedContract.id !== this.props.selectedContract.id) {
      this.setState({
        employees: [],
        filteredEmployees: [],
        selectedRows: [],
        hasExistingContract: !!this.props.selectedContract.id,
      });
      this.update();
    }
  }

  flashAddNewEmployee = () => {
    const { AddNewEmployee } = this.state;

    if (AddNewEmployee.flashed) {
      return;
    }

    if (AddNewEmployee.success) {
      this.setState({
        snackBar: {
          open: true,
          message: AddNewEmployee.message,
          variant: 'success',
        },
      });
    } else if (AddNewEmployee.error) {
      this.setState({
        snackBar: {
          open: true,
          message: AddNewEmployee.message,
          variant: 'error',
        },
      });
    }

    this.setState({ AddNewEmployee: { ...AddNewEmployee, flashed: true } });

    this.props.history.replace({ ...this.props.location, state: null });
  };

  update = () => {
    if (!this.props.selectedContract.id) {
      return;
    }
    this.setState({ loading: true });
    this.getAccounts(this.props.selectedContract);
  };

  getAccounts = (selectedContract) => {
    employeeService
      .getAccounts(selectedContract.id)
      .then((response) => {
        this.handleResponseEmployees(response);
      })
      .catch((error) => {
        console.error('Error loading employees', error);
        this.setState({ loading: false });
      });
  };

  handleResponseEmployees = (response) => {
    const employeesData = response.data.response.map((employee, index) => ({
      rowId: index,
      personId: employee.cpf,
      cpf: utils.formatCPF(employee.cpf),
      person_name: employee.person_name,
      registration: employee.registration,
      company_cnpj: employee.company_cnpj,
      company_name: employee.company_name,
      account_id: employee.account_id,
      admission_date: employee.admission_date,
      has_dismissed: employee.has_dismissed,
      hasDismissedText: employee.has_dismissed ? 'Desligado' : 'Ativo',
      update_at: employee.dismissed_date || employee.admission_date,
      status: employee.status,
      action: { isEdit: false },
    }));

    if (response.data.success) {
      this.setState({
        employees: employeesData,
        filteredEmployees: this.applyFilters(employeesData),
        loading: false,
      });
      this.flashAddNewEmployee();
    } else {
      this.setState({ loading: false });
    }
  };

  applyFilters = (employees) => {
    const { search, statusFilter } = this.state;

    return employees.filter(
      (employee) =>
        (employee.person_name.toLowerCase().includes(search.toLowerCase()) ||
          employee.cpf.includes(search)) &&
        (statusFilter === '' ||
          (statusFilter === 'active' && !employee.has_dismissed) ||
          (statusFilter === 'inactive' && employee.has_dismissed))
    );
  };

  handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();

    this.setState({ search: searchTerm }, () => {
      this.setState({
        filteredEmployees: this.applyFilters(this.state.employees),
      });
    });
  };

  handleStatusChange = (event) => {
    const status = event.target.value;

    this.setState({ statusFilter: status }, () => {
      this.setState({
        filteredEmployees: this.applyFilters(this.state.employees),
      });
    });
  };

  handleAddNewEmployee = () => {
    const { history } = this.props;
    history.push(`/app/funcionarios/adicionar`);
  };

  handleRowSelection = (selectedRows) => {
    this.setState({ selectedRows });
  };

  fireEmployeeContracts = (terminations) => {
    const { selectedContract } = this.props;
    this.setState({ loading: true });
    employeeService
      .fireEmployeeContracts(selectedContract.id, terminations)
      .then((response) => {
        if (!response.data.success) {
          this.setState({
            snackBar: {
              open: true,
              message: 'Erro ao desligar funcionários',
              variant: 'error',
            },
          });
        }

        const allTerminationsSuccess = response.data.response.employees.every(
          (employee) => employee.success
        );

        this.setState({
          snackBar: {
            open: true,
            message: allTerminationsSuccess
              ? 'Funcionários desligados com sucesso'
              : 'Falha ao desligar funcionários, tentar novamente em alguns instantes',
            variant: allTerminationsSuccess ? 'success' : 'error',
          },
        });
      })
      .catch((error) => {
        console.error('Error firing employees', error);
        this.setState({
          snackBar: {
            open: true,
            message: 'Erro ao desligar funcionários',
            variant: 'error',
          },
        });
      })
      .finally(() => {
        this.update();
        this.setState({ loading: false });
      });
  };

  reactivateEmployeesByContract = (reactivations) => {
    const { selectedContract } = this.props;
    this.setState({ loading: true });
    employeeService
      .reactivateEmployeesByContract(selectedContract.id, reactivations)
      .then((response) => {
        if (!response.data.success) {
          this.setState({
            snackBar: {
              open: true,
              message: 'Erro ao desligar funcionários',
              variant: 'error',
            },
          });
        }

        const allEmployeesSuccessStatus =
          response.data.response.employees.every(
            (employee) => employee.success
          );

        this.setState({
          snackBar: {
            open: true,
            message: allEmployeesSuccessStatus
              ? 'Funcionários reativados com sucesso'
              : 'Falha ao reativar funcionários, tente novamente em alguns instantes',
            variant: allEmployeesSuccessStatus ? 'success' : 'error',
          },
        });
      })
      .catch((error) => {
        console.error('Error reactivating employees', error);
        this.setState({
          snackBar: {
            open: true,
            message: 'Erro ao reativar os funcionários',
            variant: 'error',
          },
        });
      })
      .finally(() => {
        this.update();
        this.setState({ loading: false });
      });
  };

  handleDialogConfirm = (dialogAction, menuAction) => {
    this.setState({
      confirmDialogReactivate: {
        open: false,
      },
      confirmDialogInactivate: {
        open: false,
      },
    });

    if (dialogAction !== SUCCESS) {
      return;
    }

    switch (menuAction) {
      case MENU_ACTIONS.REACTIVATE:
        const reactivateEmployees =
          this.state.confirmDialogReactivate.employees.map((employee) => ({
            cpf: employee.cpf,
            account_id: employee.account_id,
          }));

        this.reactivateEmployeesByContract(reactivateEmployees);
        break;
      case MENU_ACTIONS.INACTIVATE:
        const fireEmployees = this.state.confirmDialogInactivate.employees.map(
          (employee) => ({
            cpf: employee.cpf,
          })
        );

        this.fireEmployeeContracts(fireEmployees);
        break;
      default:
        break;
    }
  };

  handleToggleMenuItemClick = (action, rows) => {
    this.setState({
      statusToggleMenu: {
        open: false,
      },
    });

    switch (action) {
      case MENU_ACTIONS.REACTIVATE:
        this.setState({
          confirmDialogReactivate: {
            open: true,
            employees: rows.filter((row) => row.has_dismissed),
            message:
              'A inclusão de colaboradores irá refletir em aumento no valor da mensalidade',
          },
        });
        break;
      case MENU_ACTIONS.INACTIVATE:
        this.setState({
          confirmDialogInactivate: {
            open: true,
            employees: rows.filter((row) => !row.has_dismissed),
            message:
              'A exclusão de colaboradores irá refletir em redução no valor da mensalidade',
          },
        });
        break;
      default:
        break;
    }
  };

  isShowToggleMenuButton = () => {
    const isShow = this.state.selectedRows.length > 0;
    
    if (!isShow && this.state.statusToggleMenu.open) {
      this.setState({
        statusToggleMenu: {
          open: false,
          anchorEl: null,
        },
      });
    }
    return isShow;
  };

  handleToggleStatusMenu = (event) => {
    const { open } = this.state.statusToggleMenu;

    this.setState({
      statusToggleMenu: {
        open: !open,
        anchorEl: event.currentTarget,
      },
    });
  };

  handleActionMenuClick = (action, row) => {
    switch (action) {
      case MENU_ACTIONS.REACTIVATE:
        if (row.has_dismissed === 'Ativo') {
          this.setState({
            snackBar: {
              open: true,
              message: 'Funcionário já ativo',
              variant: 'warning',
            },
          });
          return;
        }

        this.setState({
          confirmDialogReactivate: {
            open: true,
            employees: [
              {
                cpf: row.personId,
                account_id: row.account_id,
                name: row.person_name,
              },
            ],
            message:
              'A inclusão de colaboradores irá refletir em aumento no valor da mensalidade',
          },
        });
        break;
      case MENU_ACTIONS.INACTIVATE:
        if (row.has_dismissed === 'Desligado') {
          this.setState({
            snackBar: {
              open: true,
              message: 'Funcionário já desligado',
              variant: 'warning',
            },
          });
          return;
        }

        this.setState({
          confirmDialogInactivate: {
            open: true,
            employees: [
              {
                cpf: row.personId,
                name: row.person_name,
              },
            ],
            message:
              'A exclusão de colaboradores irá refletir em redução no valor da mensalidade',
          },
        });
        break;
      default:
        break;
    }
  };

  render() {
    const {
      filteredEmployees,
      loading,
      search,
      statusFilter,
      employees,
      statusToggleMenu,
      selectedRows,
      snackBar,
      confirmDialogReactivate,
      confirmDialogInactivate,
      hasExistingContract,
    } = this.state;

    return (
      <div className='app-wrapper'>
        <Grid container direction='column' spacing={0} style={{ gap: '24px' }}>
          <Grid item xs={12}>
            <ContainerHeader title='Funcionários' />
          </Grid>

          {!hasExistingContract && !loading ? (
            <Grid item xs={12}>
              <Typography
                variant='h6'
                color='textSecondary'
                style={{ margin: '20px 0' }}
              >
                Selecione um contrato
              </Typography>
            </Grid>
          ) : (
            <React.Fragment>
              <Grid item xs={12}>
                <YaloSearchFilter
                  onChange={this.handleSearch}
                  value={search}
                  placeholder='Busca por nome ou cpf'
                />
              </Grid>
              <Grid container direction='row' spacing={0}>
                <Grid item xs={4} md={4}>
                  <FormControl>
                    <SelectUi
                      value={statusFilter}
                      onChange={this.handleStatusChange}
                      label='Status'
                      options={STATUS.map((el) => ({
                        value: el.VALUE,
                        name: el.LABEL,
                      }))}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  container
                  direction='row'
                  spacing={24}
                  justify='flex-end'
                  alignItems='center'
                  alignContent='center'
                  item
                  xs={8}
                  md={8}
                  className='button-container'
                >
                  {this.isShowToggleMenuButton() && (
                    <Button
                      variant='primary'
                      size='md'
                      color='primary'
                      onClick={(event) => this.handleToggleStatusMenu(event)}
                      isDisabled={false}
                    >
                      <div style={{ display: 'flex' }}>
                        Alterar status
                        <Icon className='button-icon'>stat_minus_1</Icon>
                      </div>
                    </Button>
                  )}
                  <Button
                    variant='primary'
                    size='md'
                    color='primary'
                    onClick={() => this.handleAddNewEmployee()}
                    isDisabled={selectedRows.length > 0}
                  >
                    <div style={{ display: 'flex' }}>
                      Adicionar funcionário
                      <Icon className='button-icon'>add_circle</Icon>
                    </div>
                  </Button>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <ListEmployeeTable
                  data={filteredEmployees}
                  columns={columns}
                  loading={loading}
                  pageSize={20}
                  onSelected={(e) => {
                    this.handleRowSelection(e);
                  }}
                  onActionMenuClick={(e, a, row) => {
                    this.handleActionMenuClick(a, row, e);
                  }}
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
        <StatusToggleMenu
          open={statusToggleMenu.open}
          rows={selectedRows}
          anchorEl={statusToggleMenu.anchorEl}
          onMenuItemClick={(event, action, rows) =>
            this.handleToggleMenuItemClick(action, rows, event)
          }
        />
        <CustomizedSnackBar
          message={snackBar.message}
          variant={snackBar.variant}
          open={snackBar.open}
          onClose={() =>
            this.setState({
              snackBar: { ...snackBar, open: false },
            })
          }
        />
        <ConfirmationDialog
          open={confirmDialogReactivate.open}
          onClose={(dialogAction) =>
            this.handleDialogConfirm(dialogAction, MENU_ACTIONS.REACTIVATE)
          }
          title=''
          subTitle='Ao clicar em confirmar, você vai reativar no sistema:'
          content={
            <ConfirmationDialogBody
              message={confirmDialogReactivate.message}
              employees={confirmDialogReactivate.employees}
            />
          }
        />
        <ConfirmationDialog
          open={confirmDialogInactivate.open}
          onClose={(dialogAction) =>
            this.handleDialogConfirm(dialogAction, MENU_ACTIONS.INACTIVATE)
          }
          title=''
          subTitle='Ao clicar em confimar, você vai inativar no sistema:'
          content={
            <ConfirmationDialogBody
              message={confirmDialogInactivate.message}
              employees={confirmDialogInactivate.employees}
            />
          }
        />
      </div>
    );
  }
}

const mapStateToProps = ({ contract }) => {
  const { selectedContract } = contract;
  return { selectedContract };
};

export default connect(mapStateToProps, {})(withRouter(EmployeesConsulting));
