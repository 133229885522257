import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import {
  Checkbox,
  Grid,
  Typography,
  Divider,
  Tooltip
} from '@material-ui/core';
import { utils } from './../../../../utils';

export const ReviewChange = ({
  resume,
  handleBenefits,
  benefitsStartedImmediately
}) => {
  return (
    <Grid container spacing={32} justify='center' style={{ marginTop: 32 }}>
      {/* Lado esquendo */}
      <Grid
        item
        xs={5}
        className='p-3'
        style={{
          borderRadius: 6,
          borderWidth: 1,
          borderColor: '#dfdfdf',
          borderStyle: 'solid',
          marginRight: 5
        }}
      >
        <Typography variant='h4' style={{ fontWeight: 700 }}>
          RESUMO DA ALTERAÇÃO
        </Typography>

        <Divider className='my-2' />

        <Grid container justify='space-between'>
          <Typography variant='subtitle1' style={{ fontWeight: 700 }}>
            Novos funcionários
          </Typography>
          <Typography variant='subtitle2' style={{ fontWeight: 700 }}>
            {resume.totalNewEmployees}
          </Typography>
        </Grid>
        <Grid container justify='space-between'>
          <Typography variant='subtitle1' style={{ fontWeight: 700 }}>
            Desligamentos
          </Typography>
          <Typography variant='subtitle2' style={{ fontWeight: 700 }}>
            {resume.totalTerminations}
          </Typography>
        </Grid>

        <Grid container justify='space-between'>
          <Typography variant='subtitle1' style={{ fontWeight: 700 }}>
            YALO Corporativo
          </Typography>
          <Typography variant='subtitle2' style={{ fontWeight: 700 }}>
            {resume.totalPlans}
          </Typography>
        </Grid>
        <Grid xs={12} item style={{ marginTop: '.5rem' }}>
          <Typography
            variant='subtitle1'
            style={{ marginLeft: -14, marginBottom: -10, cursor: 'pointer' }}
            onClick={e => handleBenefits()}
            color='textPrimary'
            inline
          >
            <Checkbox
              checked={benefitsStartedImmediately}
              style={{ height: 10 }}
              color='primary'
            />
            Benefícios com inicio imediato?
          </Typography>{' '}
          <Tooltip
            title='Com essa opção desativada, os benefícios só terão vigor no próximo dia 1º.'
            // open={!benefitsStartedImmediately}
            interactive
            leaveDelay={0}
          >
            <InfoIcon
              style={{ verticalAlign: 'sub', cursor: 'pointer' }}
              fontSize='small'
              color='primary'
            />
          </Tooltip>
        </Grid>
      </Grid>

      {/* lado direito */}
      <Grid
        item
        xs={5}
        className='p-0'
        style={{
          borderRadius: 6,
          backgroundColor: '#f0f0f0'
        }}
        container
        direction='column'
        justify='space-between'
      >
        <Grid className='p-3'>
          <Typography variant='h4' style={{ fontWeight: 700 }}>
            QUANTIDADE DE FUNCIONÁRIOS
          </Typography>
          <Divider className='my-2' />
          <Grid container justify='space-between'>
            <Typography variant='subtitle1' style={{ fontWeight: 700 }}>
              Último mês
            </Typography>
            <Typography variant='subtitle2' style={{ fontWeight: 700 }}>
              {resume.totalRecharges + resume.totalTerminations}
            </Typography>
          </Grid>
          <Grid container justify='space-between'>
            <Typography variant='subtitle1' style={{ fontWeight: 700 }}>
              Após processamento
            </Typography>
            <Typography variant='subtitle2' style={{ fontWeight: 700 }}>
              {resume.totalPlans}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          className='p-3'
          style={{
            backgroundColor: 'rgb(223, 223, 223)'
          }}
        >
          <Grid container justify='space-between'>
            <Typography variant='subtitle2' style={{ fontWeight: 700 }}>
              Mensalidade
            </Typography>
            <Typography variant='subtitle2' style={{ fontWeight: 700 }}>
              {`R$ ${utils.formatCurrency(
                resume.totalValuePlans + resume.totalValueTaxes
              )}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
