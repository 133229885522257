import React, { Component, Fragment } from 'react';
import { FormControl, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Select from '../../../../../../components/ui/select';
import TextField from '../../../../../../components/ui/TextField';

import { utils } from '../../../../utils';
import { pjService } from '../../../../../../services/pjService';

import { styles } from '../styles';

class FinancialResponsible extends Component {
  state = {
    financial_resp: {
      nome: '',
      cpf: '',
      sexo: '',
      data_nascimento: '',
      telefone: '',
      email: '',
    },
    touched: {
      nome: false,
      cpf: false,
      email: false,
      telefone: false,
    },
    errors: {
      nome: 'Por favor, insira um nome.',
      cpf: 'Por favor, insira um CPF.',
      sexo: 'Por favor, insira um sexo.',
      data_nascimento: '',
      telefone: 'Por favor, insira um telefone.',
      email: 'Por favor, insira um e-mail.',
    },
    personFetched: false,
    loading: false,
    replicate: false,
  };

  componentDidMount() {
    this.props.setActiveComponentStep(this);

    if (this.props.financial_resp.cpf !== undefined) {
      const errors = {
        nome:
          this.props.financial_resp.nome === null ||
          this.props.financial_resp.nome === ''
            ? 'por favor, insira um nome.'
            : '',
        cpf:
          this.props.financial_resp.cpf === null ||
          this.props.financial_resp.cpf === ''
            ? 'por favor, insira um cpf.'
            : '',
        sexo:
          this.props.financial_resp.sexo === null ||
          this.props.financial_resp.sexo === ''
            ? 'por favor, insira um sexo.'
            : '',
        data_nascimento: '',
        telefone:
          this.props.financial_resp.telefone === null ||
          this.props.financial_resp.telefone === ''
            ? 'por favor, insira um telefone.'
            : '',
        email:
          this.props.financial_resp.email === null ||
          this.props.financial_resp.email === ''
            ? 'por favor, insira um email.'
            : '',
      };

      this.setState({
        financial_resp: this.props.financial_resp,
        personFetched: this.props.financial_resp.personFetched,
        errors,
      });

      this.props.currentComponentStepChange(this.inValidateForm(errors));
    } else {
      this.props.currentComponentStepChange(
        this.inValidateForm(this.state.errors)
      );
    }
  }

  updateCurrentState() {
    this.props.setInputs(this.state.financial_resp);
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    let value = target.value;
    if (name === 'cpf') {
      value = utils.handleCPF(value);
    }
    if (name === 'telefone') {
      value = utils.handleCellphone(value);
    }
    this.setState({
      financial_resp: { ...this.state.financial_resp, [name]: value },
    });
  }

  handleCpfFetch = (response) => {
    if (response.success) {
      const person = response.response;
      person.birth_date = !person.birth_date
        ? ''
        : (person.birth_date = person.birth_date.slice(0, 10));
      if (person.phone) {
        person.phone =
          person.phone.length > 0
            ? utils.handleCellphone(
                person.phone[0].area_code.split('0')[1] + person.phone[0].number
              )
            : '';
      }

      const errors = {
        ...this.state.errors,
        nome: '',
        sexo: '',
        data_nascimento:
          person.birth_date !== '' ? '' : this.state.errors.data_nascimento,
        telefone: person.phone !== '' ? '' : this.state.errors.telefone,
        email: person.email === '' ? this.state.errors.email : '',
      };

      this.setState({
        financial_resp: {
          ...this.state.financial_resp,
          nome: person.name,
          sexo: person.gender,
          data_nascimento: person.birth_date,
          telefone: person.phone,
          email: person.email,
        },
        touched: {
          ...this.state.touched,
          nome: true,
          sexo: true,
          data_nascimento: person.birth_date !== '',
          telefone: person.phone !== '',
          email: person.email === '' ? false : true,
        },
        errors,
        personFetched: true,
        loading: false,
      });

      this.props.currentComponentStepChange(this.inValidateForm(errors));
    } else {
      this.setState({
        personFetched: false,
        loading: false,
      });
    }
  };

  validate = (e) => {
    let error = e.target.value ? '' : 'Por Favor, preencha este campo.';
    if (e.target.name === 'cpf') {
      error = utils.validateCpf(e.target.value);
      if (error === '') {
        this.setState({ loading: true });
        pjService
          .getByCpf(
            utils.removeNonNumericCharacters(
              `${this.state.financial_resp.cpf}`,
            ),
          )
          .then((response) => this.handleCpfFetch(response));
      }
    }

    if (e.target.name === 'telefone') {
      error =
        e.target.value.length >= 14
          ? ''
          : 'Por favor, insira um telefone válido.';
    }

    if (e.target.type === 'date') {
      error = utils.validateDate(e.target.value)
        ? ''
        : 'Por favor, insira uma data válida.';
      error = e.target.value === '' ? '' : error;
    }

    if (e.target.name === 'email') {
      error =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          e.target.value
        )
          ? ''
          : 'Por favor, insira um email válido.';
    }

    const errors = { ...this.state.errors, [e.target.name]: error };

    this.setState({
      errors,
      touched: { ...this.state.touched, [e.target.name]: true },
    });

    this.props.currentComponentStepChange(this.inValidateForm(errors));
  };

  inValidateForm(errors) {
    if (this.state.personFetched) return false;
    const { nome, cpf, email, data_nascimento, telefone, sexo } = errors;
    if (!cpf && !nome && !data_nascimento && !email && !telefone && !sexo) {
      return false;
    }

    return true;
  }

  render() {
    const { financial_resp, touched, errors } = this.state;
    const { classes, confirmation } = this.props;

    return (
      <Fragment>
        <Grid
          container
          direction='column'
          justify='flex-start'
          alignItems='flex-start'
          spacing={0}
          className={classes.root}
        >
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                name='cpf'
                id='fin_res_cpf'
                label='CPF'
                required={true}
                value={financial_resp.cpf}
                onChange={(e) => this.handleInputChange(e)}
                onBlur={(e) => this.validate(e)}
                error={touched.cpf && errors.cpf !== ''}
                type='text'
                disabled={this.state.loading || confirmation}
                formInputHelper={{
                  title: 'CPF',
                  text: errors.cpf,
                  showIcon: true,
                  isShow: touched.cpf && errors.cpf !== '',
                }}
              />
            </Grid>
            <Grid item xs={8} md={8} className={classes.columnSpacer}>
              <TextField
                small={true}
                name='nome'
                id='fin_res_nome'
                label='Nome'
                required={true}
                value={financial_resp.nome}
                onChange={(e) => this.handleInputChange(e)}
                onBlur={(e) => this.validate(e)}
                error={touched.nome && errors.nome !== ''}
                type='text'
                disabled={this.state.loading || confirmation}
                formInputHelper={{
                  title: 'Nome',
                  text: errors.nome,
                  showIcon: true,
                  isShow: touched.nome && errors.nome !== '',
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <FormControl fullWidth margin='none'>
                <Select
                  name='sexo'
                  id='fin_res_sexo'
                  label='Gênero'
                  value={financial_resp.sexo}
                  onChange={(e) => {
                    this.handleInputChange(e);
                    this.validate(e);
                  }}
                  onBlur={(e) => {
                    this.validate(e);
                  }}
                  error={touched.sexo && errors.sexo !== ''}
                  errorMessage={touched.sexo && errors.sexo}
                  isDisabled={this.state.loading || confirmation}
                  options={[
                    { value: 'M', name: 'Masculino' },
                    { value: 'F', name: 'Feminino' },
                  ]}
                ></Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                name='data_nascimento'
                id='fin_res_data_nascimento'
                label='Data de Nascimento'
                value={financial_resp.data_nascimento}
                onChange={(e) => this.handleInputChange(utils.capDate(e))}
                onBlur={(e) => this.validate(e)}
                error={errors.data_nascimento !== ''}
                type='date'
                disabled={this.state.loading || confirmation}
                formInputHelper={{
                  title: 'Data de Nascimento',
                  text: errors.data_nascimento,
                  showIcon: true,
                  isShow: errors.data_nascimento !== '',
                }}
              />
            </Grid>
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                name='telefone'
                id='fin_res_telefone'
                label='Telefone'
                required={true}
                value={financial_resp.telefone}
                onChange={(e) => this.handleInputChange(e)}
                onBlur={(e) => this.validate(e)}
                error={touched.telefone && errors.telefone !== ''}
                type='text'
                disabled={this.state.loading || confirmation}
                formInputHelper={{
                  title: 'Telefone',
                  text: errors.telefone,
                  showIcon: true,
                  isShow: touched.telefone && errors.telefone !== '',
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <TextField
                small={true}
                name='email'
                id='fin_res_email'
                label='Email'
                required={true}
                value={financial_resp.email}
                onChange={(e) => this.handleInputChange(e)}
                onBlur={(e) => this.validate(e)}
                error={touched.email && errors.email !== ''}
                type='text'
                disabled={this.state.loading || confirmation}
                formInputHelper={{
                  title: 'Email',
                  text: errors.email,
                  showIcon: true,
                  isShow: touched.email && errors.email !== '',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(FinancialResponsible);
