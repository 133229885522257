import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TableCell, IconButton, Icon, withStyles } from '@material-ui/core';
import FloatMenuAction from './FloatMenuAction';

const styles = (theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    width: '100%',
  },
});

const TableCellAction = ({ row, classes, onClick, onActionMenuClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const cellRef = useRef(null);

  return (
    <TableCell
      ref={cellRef}
      className={classes.root}
      padding='checkbox'
      data-row-id={row.rowId}
      data-row-action={true}
    >
      <IconButton
        aria-label='Ações'
        size='small'
        color='default'
        disabled={false}
        disableRipple={false}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          onClick(e, row.rowId);
        }}
      >
        <Icon>more_horiz</Icon>
      </IconButton>
      <FloatMenuAction
        open={row.action.isEdit}
        row={row}
        anchorEl={anchorEl}
        onMenuItemClick={onActionMenuClick}
      />
    </TableCell>
  );
};

TableCellAction.propTypes = {
  row: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onActionMenuClick: PropTypes.func.isRequired,
};

TableCellAction.defaultProps = {};

export default withStyles(styles)(TableCellAction);
