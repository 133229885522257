import React, { Component, Fragment } from 'react';
import { FormControl, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Select from '../../../../../../components/ui/select';
import TextField from '../../../../../../components/ui/TextField';

import { styles } from '../styles';

class GovReg extends Component {
  state = {
    regs: {
      isento_est: '',
      insc_est: '',
      isento_mun: '',
      insc_mun: '',
      tax_regime: '',
      tax_regime_type: '',
    },
    errors: {
      isento_est: '',
      insc_est: '',
      isento_mun: '',
      insc_mun: '',
      tax_regime: '',
      tax_regime_type: '',
    },
    touched: {
      isento_est: false,
      insc_est: false,
      isento_mun: false,
      insc_mun: false,
      tax_regime: false,
      tax_regime_type: false,
    },
  };

  componentDidMount() {
    this.props.setActiveComponentStep(this);
    if (this.props.regs.isento_est !== undefined) {
      const regs = this.props.regs;
      const errors = {
        isento_est: !regs.isento_est ? 'Por favor, preencha este campo.' : '',
        insc_est: '',
        isento_mun: !regs.isento_mun ? 'Por favor, preencha este campo.' : '',
        insc_mun: '',
        tax_regime: !regs.tax_regime ? 'Por favor, preencha este campo.' : '',
        tax_regime_type: '',
      };

      this.setState({
        regs: regs,
        errors: errors,
      });

      this.props.currentComponentStepChange(this.inValidateForm(errors, regs));
    }
  }

  updateCurrentState() {
    this.props.setInputs(this.state.regs);
  }

  handleSelectChange(event) {
    const target = event.target;
    const name = target.name;
    let value = target.value;
    const field = ['isento_est', 'isento_mun'];
    const insc = `insc_${name.split('_')[1]}`;
    let errors = { ...this.state.errors };
    let regs = { ...this.state.regs };

    function clearRelatedErrors(name){
      field.forEach(fieldName => {
        if (fieldName !== name) {
          errors[fieldName] = '';
        }
      });
    };

    if (field.includes(name) && value === 'sim') {
      errors = { ...errors, [insc]: '' };
      regs = { ...regs, [name]: value, [insc]: 'Isento' };
     clearRelatedErrors(name);
      this.setState({
        regs,
        errors,
      });
    } else if (field.includes(name) && value === 'nao') {
      errors = { ...errors, [insc]: 'Por favor, preencha este campo.' };
      regs = { ...regs, [name]: value, [insc]: '' };
      clearRelatedErrors(name);
      this.setState({
        regs,
        errors,
        touched: {
          ...this.state.touched,
          [insc]: true,
        },
      });
    } else {
      errors = {
        ...errors,
        [name]: value === '' ? 'Por favor, preencha este campo.' : '',
      };
      regs = { ...regs, [name]: value };

      this.setState({
        regs,
        errors,
        touched: { ...this.state.touched, [name]: true }
      });
    }

    this.props.currentComponentStepChange(this.inValidateForm(errors, regs));
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    this.setState({ regs: { ...this.state.regs, [name]: value },});
  }

  validate(event) {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    const error = value ? '' : 'Por Favor, preencha este campo.';

    const errors = { ...this.state.errors, [name]: error };

    this.setState({
      errors,
      touched: { ...this.state.touched, [name]: true },
    });

    this.props.currentComponentStepChange(
      this.inValidateForm(errors, this.state.regs),
    );
  }

  inValidateForm(errors, regs) {
    const { isento_est, insc_est, isento_mun, insc_mun, } = errors;

    const hasValues =
      regs.isento_est !== '' &&
      regs.isento_mun !== '';

    const noErrors = isento_est === '' && insc_est === '' && isento_mun === '' && insc_mun === '';

    if (hasValues && noErrors) {
      return false;
    }

    return true;
  }

  render() {
    const { regs, errors, touched } = this.state;
    const { classes, confirmation } = this.props;
    return (
      <Fragment>
        <Grid
          container
          direction='column'
          justify='flex-start'
          alignItems='flex-start'
          spacing={0}
          className={classes.root}
        >
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <FormControl fullWidth margin='none'>
                <Select
                  name='isento_est'
                  id='gov_reg_isento_est'
                  label='Isento de Inscrição Estadual?'
                  value={regs.isento_est}
                  onChange={(e) => this.handleSelectChange(e)}
                  onBlur={(e) => this.validate(e)}
                  isDisabled={confirmation}
                  errorMessage={touched.isento_est && errors.isento_est}
                  options={[
                    { value: 'sim', name: 'Sim' },
                    { value: 'nao', name: 'Não' },
                  ]}
                ></Select>
              </FormControl>
            </Grid>
            <Grid item xs={8} md={8} className={classes.columnSpacer}>
              <TextField
                small={true}
                name='insc_est'
                id='gov_reg_insc_est'
                label='Inscrição Estadual'
                value={regs.insc_est}
                onChange={(e) => this.handleInputChange(e)}
                onBlur={(e) => this.validate(e)}
                disabled={confirmation || regs.isento_est === 'sim'}
                error={touched.insc_est && errors.insc_est !== ''}
                formInputHelper={{
                  title: 'Inscrição Estadual',
                  text: errors.insc_est,
                  showIcon: true,
                  isShow: touched.insc_est && errors.insc_est !== '',
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <FormControl fullWidth margin='none'>
                <Select
                  name='isento_mun'
                  id='gov_reg_isento_mun'
                  label='Isento de Inscrição Municipal?'
                  value={regs.isento_mun}
                  onChange={(e) => this.handleSelectChange(e)}
                  onBlur={(e) => this.validate(e)}
                  isDisabled={confirmation}
                  errorMessage={touched.isento_mun && errors.isento_mun}
                  options={[
                    { value: 'sim', name: 'Sim' },
                    { value: 'nao', name: 'Não' },
                  ]}
                ></Select>
              </FormControl>
            </Grid>
            <Grid item xs={8} md={8} className={classes.columnSpacer}>
              <TextField
                small={true}
                name='insc_mun'
                id='gov_reg_insc_mun'
                label='Inscrição Municipal'
                value={regs.insc_mun}
                onChange={(e) => this.handleInputChange(e)}
                onBlur={(e) => this.validate(e)}
                disabled={confirmation || regs.isento_mun === 'sim'}
                error={touched.insc_mun && errors.insc_mun !== ''}
                formInputHelper={{
                  title: 'Inscrição Municipal',
                  text: errors.insc_mun,
                  showIcon: true,
                  isShow: touched.insc_mun && errors.insc_mun !== '',
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
            spacing={0}
            className={classes.rowSpacer}
          >
            <Grid item xs={4} md={4} className={classes.columnSpacer}>
              <FormControl fullWidth margin='none'>
                <Select
                  name='tax_regime'
                  id='gov_reg_tax_regime'
                  label='Regime Tributário'
                  value={regs.tax_regime}
                  isNotRequried={true}
                  onChange={(e) => this.handleSelectChange(e)}
                  onBlur={(e) => this.validate(e)}
                  isDisabled={confirmation}
                  options={[
                    { value: 'Simples', name: 'Simples Nacional' },
                    { value: 'Lucro Real', name: 'Lucro Real' },
                    { value: 'Presumido', name: 'Lucro Presumido' },
                  ]}
                ></Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(GovReg);
