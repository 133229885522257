export default {
  shadows: ['none'],
  palette: {
    // https://material-ui.com/customization/themes/#palette
    primary: {
      light: '#FFF',
      main: '#3A10E0',
      dark: '#404040',
      brand100: '#FCFBFE',
      brand200: '#F2F1FD',
      brand300: '#E2DFFA',
      brand400: '#CBC7F6',
      brand500: '#ADA7F1',
      brand600: '#8980EB',
      brand700: '#5F53E4',
      brand800: '#3A10E0',
      brand900: '#071060',
      neutral100: "#FFFFFF",
    },
    secondary: {
      light: '#636F86',
      main: '#404040',
      dark: '#101D30'
    },
    error: {
      light: '#FFE0F1',
      main: '#89024C'
    },
    background: {
      paper: "#fff",
      default: "#F6F6F6",
    },
  },
  typography: {
    // https://material-ui.com/customization/themes/#typography
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Sua dr consulta',
      'Inter',
      'sans-serif',
    ].join(','),
    h1: {
      fontWeight: 700,
      fontSize: 24,
      color: '#707070',
      lineHeight: 1.75
    },
    h2: {
      fontWeight: 700,
      fontSize: 20,
      color: '#3A10E0',
      lineHeight: '22px'
    },
    h3: {
      fontWeight: 700,
      fontSize: 18,
      color: '#3A10E0',
      lineHeight: 1.75
    },
    h4: {
      fontWeight: 700,
      fontSize: 16,
      color: '#3A10E0',
      lineHeight: 1.75
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 14,
      color: '#1A1A1A',
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: 14,
      color: 'rgba(56, 68, 89, 0.87)',
      lineHeight: 1.75
    },
    body1: {
      fontFamily: 'Inter',
      fontWeight: '400',
      fontSize: '14px',
      color: '#1A1A1A',

    },
    body2: {
      fontFamily: 'Inter',
      fontWeight: '600',
      fontSize: '14px',
      color: '#1A1A1A',
    },
    button: {
      fontWeight: 700
    }
  },
  overrides: {
    //   https://material-ui.com/customization/overrides/#overrides
    // Name of the component ⚛️
    // Para utilizar as classes de colorir, basta aplicar a classe ao objeto, exemplo <IconX className="green-info"/>
    MuiExpansionPanel: {
      // The properties to apply
      root: {
        borderRadius: 4,
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 16,
        marginBottom: 16,
        '&:before': {
          display: 'none'
        },
        '&.white-info': {
          color: 'rgba(255, 255, 255, 1)'
        },
        '&.green-info': {
          borderLeft: '4px solid rgba(77, 184, 72, 0.87)'
        },
        '&.red-info': {
          borderLeft: '4px solid rgba(233, 0, 34, 0.87)'
        },
        '&.orange-info': {
          borderLeft: '4px solid rgba(242, 153, 74, 0.87)'
        },
        '&.blue-info': {
          borderLeft: '4px solid rgba(0, 145, 224, 0.87)'
        }
      }
    },
    MuiTypography: {
      root: {
        '&.white-info': {
          color: 'rgba(255, 255, 255, 1)'
        },
        '&.green-info': {
          color: '#143E47',
          backgroundColor: '#E5FAF4'
        },
        '&.red-info': {
          color: "#89024C",
          backgroundColor: "#FFE0F1"
        },
        '&.orange-info': {
          color: '#E07B10',
          backgroundColor: '#FFE5E0'
        },
        '&.blue-info': {
          color: '#3A10E0',
          backgroundColor: '#E0E7FF' 
        },
        '&.status-success': {
          color: '#143E47',
          backgroundColor: '#E5FAF4',
        },
        '&.status-info': {
          color: '#646464',
          backgroundColor: '#F2F2F0',
        },
        '&.status-warning': {
          color: "#93370D",
          backgroundColor: "#FEF0C7"
        },
        '&.status-error': {
          color: '#89024C',
          backgroundColor: '#FFE0F1'
        },
      }
    },
    MuiSvgIcon: {
      root: {
        '&.white-info': {
          color: 'rgba(255, 255, 255, 1)'
        },
        '&.green-info': {
          color: 'rgba(77, 184, 72, 0.87)'
        },
        '&.red-info': {
          color: 'rgba(233, 0, 34, 0.87)'
        },
        '&.orange-info': {
          color: 'rgba(242, 153, 74, 0.87)'
        },
        '&.blue-info': {
          color: 'rgba(0, 145, 224, 0.87)'
        }
      }
    },
    MuiIcon: {
      root: {
        '&.white-info': {
          color: 'rgba(255, 255, 255, 1)'
        },
        '&.green-info': {
          color: 'rgba(77, 184, 72, 0.87)'
        },
        '&.red-info': {
          color: 'rgba(233, 0, 34, 0.87)'
        },
        '&.orange-info': {
          color: 'rgba(242, 153, 74, 0.87)'
        },
        '&.blue-info': {
          color: 'rgba(0, 145, 224, 0.87)'
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '.8rem',
        backgroundColor: '#3A10E0',
        color: 'white'
      }
    }
  },
  zIndex: {
    modal: 1300,
    floatMenu: 1260,
  }
};
