import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Grid, Paper, CircularProgress, Icon } from '@material-ui/core';
import IconCloudDownload from '@material-ui/icons/CloudDownloadRounded';

import { SUCCESS, ERROR } from './../../../../../constants/ActionTypes';
import ContainerHeader from '../../../../../components/ContainerHeader/index';
import SelectFile from './subcomponents/SelectFile';
import { ReviewFile } from './subcomponents/ReviewFile';
import { ReviewChange } from './subcomponents/ReviewChange';
import ThereIsNoOrder from './subcomponents/ThereIsNoOrder';
import Steps from '../../../../../app/routes/PessoaJuridica/routes/components/Stepper';

import { employeeService, ordersService } from './../../../../../services';
import {
  modalEmitAlert,
  modalAlertShowLoading,
} from './../../../../../actions/ModalAlertActions';
import ConfirmationDialog from '../../../../../components/ConfirmationDialog/ConfirmationDialog';
import ButtonUi from '../../../../../components/ui/button/Button';
import { ChevronLeftRounded, ChevronRightRounded } from '@material-ui/icons';
import OrderStats from './subcomponents/OrderStats';

const HttpStatus = require('http-status-codes');

const SELECT_FILE = 0;
const REVIEW_FILE = 1;
const REVIEW_CHANGE = 2;

const WAITING_NF = 'waiting_nf';

const resetState = () => {
  return {
    thereIsOrder: true,
    file: '',
    order: null,
    loading: false,
    resume: null,
    dates: null,
    warnings: null,
    errors: null,
    showDetailStep2: false,
    serveFilePath: '',
    dialog: { open: false, message: 'Email enviado', typeAlert: 3 },
    editOrder: false,
    editOrderCheckBox: false,
    errorMsg: '',
    isPaymentDone: true,
    benefitsStartedImmediately: true,

    steps: [`Selecionar Arquivo`, `Revisar Arquivo`, `Revisar alteração`],
    activeStep: 0,
    stepCompleted: [],
    open: false,
    viewReport: false,
    uploadProgress: '...',
  };
};

class RegisterDisconnect extends Component {
  constructor(props) {
    super(props);
    this.state = resetState();
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.selectedContract.id !== nextProps.selectedContract.id)
      this.resetCurrentState(nextProps.selectedContract);
  };

  handleOpen = () => {
    // OPEN CONFIRMATION DIALOG
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  resetCurrentState = (selectedContract) => {
    if (!selectedContract || !selectedContract.id) {
      this.setState({
        thereIsOrder: false,
        order: ['Selecione um contrato antes de realizar o upload do arquivo'],
        uploadProgress: '...',
      });
      return;
    }

    this.setState({
      thereIsOrder: true,
      loading: false,
      order: {
        id: '',
        order_type_name: 'manual',
        order_status_name: 'pending_information_filling',
      },
      editOrder: this.isEditOrder('pending_information_filling'),
      uploadProgress: '...',
    });
  };

  isEditOrder = (status) => {
    if (status == WAITING_NF)
      // true = file was send before, user need to know that file was send
      return true;
    return false;
  };

  processManualOrder = async () => {
    const { order } = this.state;
    let state = { ...this.state };
    state.loading = true;
    this.setState({ ...state });
    // await this.props.modalAlertShowLoading();

    ordersService
      .processManualOrder(
        this.state.order.id,
        this.state.isPaymentDone,
        this.state.benefitsStartedImmediately
      )
      .then((result) => {
        let state = resetState();
        this.handleClose();
        state.loading = false;
        if (result.success) {
          state.order = result.response.order;
          if (['implantation', 'manual'].includes(order.order_type_name)) {
            this.props.modalEmitAlert(
              'Pedido de implantação salvo com sucesso.',
              SUCCESS
            );
          } else {
            this.props.modalEmitAlert('Pedido salvo com sucesso.', SUCCESS);
          }

          this.setState(state);
          this.resetCurrentState(this.props.selectedContract);
        } else {
          this.props.modalEmitAlert(
            'Erro ao criar pedido de implantação.',
            ERROR
          );
          this.setState(state);
          this.resetCurrentState(this.props.selectedContract);
        }
      });
  };

  componentDidMount = () => {
    this.resetCurrentState(this.props.selectedContract);
  };

  toggleDetail = (e) => {
    this.setState({
      ...this.state,
      showDetailStep2: !this.state.showDetailStep2,
    });
  };

  onUploadSuccess = (validatedData) => {
    this.setState({
      ...this.state,
      order: {
        ...this.state.order,
        id: validatedData.orderId,
      },
      resume: validatedData.resume,
      warnings: validatedData.warnings,
      errors: validatedData.errors,
      loading: false,
      activeStep: this.state.activeStep + 1,
      stepCompleted: [...this.state.stepCompleted, this.state.activeStep],
      serveFilePath: validatedData.path,
    });
  };

  setUploadProgressState = (progress) => {
    return new Promise((resolve) => {
      this.setState({ uploadProgress: `${progress}%` }, () => { resolve(); });
    });
  }

  onSelectFile = async (file) => {
    const { order } = this.state;
    if (this.state.file !== '') {
      this.setState({
        file: '',
        errorMsg: '',
      });
    } else {
      this.setState({
        file,
        loading: true,
        errorMsg: '',
      });
    }

    try {
      const response = await ordersService.uploadManualOrder(
        this.props.selectedContract.id,
        file
      );

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let result = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        result += decoder.decode(value, { stream: true });

        // Processa cada pedaço de JSON recebido
        const chunks = result.split('\n').filter((chunk) => chunk.trim());
        for await (const chunk of chunks) {
          try {
            const data = JSON.parse(chunk);
            if (data.status === 'error') {
              this.setState({
                file: '',
                loading: false,
                errorMsg: Array.isArray(data.message) ? data.message.join(', ') : data.message,
              });
              break;
            } else if (data.status === 'running' && data.completed !== 100) {
              await this.setUploadProgressState(data.completed);
            } else if (data.status === 'end') {
              this.setState({ uploadProgress: `${data.completed}%` }, () => { this.onUploadSuccess(data.validatedData); });
              break;
            }
          } catch (e) {
            console.error('json parser error', e);
          }
        }
      }
    } catch (error) {
      console.error(error);
      this.setState({
        file: '',
        loading: false,
        errorMsg: `Erro ao fazer upload do arquivo: ${error.message}`,
      });
    }
  };

  handleBack = () => {
    if (this.state.activeStep > SELECT_FILE) {
      let state = { ...this.state };
      state.stepCompleted.pop();

      if (state.activeStep == REVIEW_FILE) {
        const nState = resetState();
        nState.thereIsOrder = state.thereIsOrder;
        nState.loading = state.loading;
        nState.order = state.order;
        this.setState(nState, () =>
          this.resetCurrentState(this.props.selectedContract)
        );
      } else {
        state.activeStep = state.activeStep - 1;
        this.setState(state);
      }
    }
  };

  handleNext = () => {
    if (this.state.activeStep < 2) {
      let state = { ...this.state };
      state.activeStep = this.state.activeStep + 1;
      state.stepCompleted = [
        ...this.state.stepCompleted,
        this.state.activeStep,
      ];
      this.setState(state);
    } else if (this.state.activeStep == 2) {
      this.handleOpen();
    }
  };

  disabledByStep = () => {
    if (this.state.loading) return true;
    switch (this.state.activeStep) {
      case SELECT_FILE:
        return this.state.file === '';
      case REVIEW_FILE:
        return this.state.errors !== null && this.state.errors !== undefined;
      case REVIEW_CHANGE:
        return false;
    }
  };
  handleBenefits = () => {
    const { benefitsStartedImmediately } = this.state;
    this.setState({ benefitsStartedImmediately: !benefitsStartedImmediately });
  };

  render() {
    const {
      file,
      thereIsOrder,
      orderStatus,
      warnings,
      showDetailStep2,
      errors,
      loading,
      steps,
      activeStep,
      stepCompleted,
      dates,
      resume,
      editOrder,
      editOrderCheckBox,
      errorMsg,
      order,
      dialog,
      isPaymentDone,
      benefitsStartedImmediately,
    } = this.state;
    const { selectedContract } = this.props;
    let contentComponent;

    if (loading && file == '') {
      contentComponent = (
        <Grid container justify='center' style={{ padding: 100 }}>
          <CircularProgress
            mode='indeterminate'
            style={{ margin: 50, color: '#005DF9' }}
          />
        </Grid>
      );
    } else if (!thereIsOrder) {
      // STORED ERRORS ON ORDER STATE TO AVOID CREATING A NEW ERROR STATE THAT COULD BE CONFUSED WITH THE SPREADSHEET ERRORS
      contentComponent = (
        <ThereIsNoOrder errors={this.state.order}></ThereIsNoOrder>
      );
    } else if (thereIsOrder) {
      //Select Step
      switch (activeStep) {
        case 0:
          contentComponent = (
            <SelectFile
              selectedContract={selectedContract}
              orderStatus={orderStatus}
              file={file}
              loading={loading}
              warnings={warnings}
              editOrder={editOrder}
              editOrderCheckBox={editOrderCheckBox}
              errorMsg={errorMsg}
              handleEditOrderCheckBox={() =>
                this.setState((previousState) => ({
                  editOrderCheckBox: !previousState.editOrderCheckBox,
                }))
              }
              onSelectFile={this.onSelectFile}
              handleBack={this.handleBack}
              uploadProgress={this.state.uploadProgress}
            />
          );
          break;
        case 1:
          contentComponent = (
            <ReviewFile
              file={file}
              loading={loading}
              warnings={warnings}
              errors={errors}
              resume={resume}
              dates={dates}
              showDetailStep2={showDetailStep2}
              onSelectFile={this.onSelectFile}
              toggleDetail={this.toggleDetail}
              handleBack={this.handleBack}
              order={order}
            />
          );
          break;
        case 2:
          contentComponent = (
            <ReviewChange
              resume={resume}
              dates={dates}
              handleBenefits={this.handleBenefits}
              benefitsStartedImmediately={benefitsStartedImmediately}
            />
          );
          break;
        default:
          contentComponent = <SelectFile selectedContract={selectedContract} />;
          break;
      }
    }

    return (
      <div className='app-wrapper'>
        <Grid container justify='space-between'>
          <ContainerHeader title='Cadastrar / Desligar' />
          <div style={{ display: 'flex', gap: '10px', flexDirection: 'row' }}>
            <ButtonUi
              variant='link'
              style={{ marginBottom: 16 }}
              endIcon={
                <Icon style={{ marginRight: 16 }}>
                  {this.state.viewReport ? 'upload_file' : 'pending_actions'}
                </Icon>
              }
              onClick={() =>
                this.setState({ viewReport: !this.state.viewReport })
              }
            >
              {this.state.viewReport ? 'Ver Formulário' : 'Ver Relatório'}
            </ButtonUi>
            <a
              style={{ all: 'unset' }}
              download
              href='assets/files/template_planilha_mensalidade_nova_06_2020.xlsx'
              target='_blank'
            >
              <ButtonUi
                variant='link'
                style={{ marginBottom: 16 }}
                endIcon={<IconCloudDownload style={{ marginRight: 16 }} />}
              >
                Baixar planilha modelo
              </ButtonUi>
            </a>
          </div>
        </Grid>
        {/* Order Stats */}
        {this.state.viewReport && (
          <OrderStats selectedContract={selectedContract} />
        )}
        {/* navigate steps */}
        {!this.state.viewReport && (
          <Paper>
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <Grid>
                <Steps
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                  steps={steps}
                  activeStep={activeStep}
                  completed={stepCompleted}
                />
              </Grid>
            </Grid>

            {/* Content */}
            {contentComponent}

            {/* Botoes footer */}
            <Grid
              container
              justify='center'
              style={{ paddingTop: 32, paddingBottom: 16, paddingRight: 16 }}
            >
              <ButtonUi
                variant='link'
                onClick={this.handleBack}
                isDisabled={loading || activeStep === 0}
                style={{ marginTop: '10px', marginRight: '16px' }}
                startIcon={<ChevronLeftRounded />}
              >
                Voltar
              </ButtonUi>

              <ButtonUi
                variant='primary'
                size='md'
                color='primary'
                onClick={this.handleNext}
                isDisabled={this.disabledByStep()}
                endIcon={<ChevronRightRounded />}
              >
                Continuar
              </ButtonUi>
            </Grid>
          </Paper>
        )}

        <ConfirmationDialog
          title={'Confirmar o pedido?'}
          confirm={'Enviar'}
          open={this.state.open}
          handleConfirmation={this.processManualOrder}
          handleCancel={this.handleClose}
          handleClose={this.handleClose}
          loading={this.state.loading}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ contract }) => {
  const { selectedContract } = contract;
  return { selectedContract };
};
export default connect(mapStateToProps, {
  modalEmitAlert,
  modalAlertShowLoading,
})(withRouter(RegisterDisconnect));
